var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"smallFont focusable",attrs:{"id":_vm.result.id + '_card',"flat":""},on:{"click":function($event){return _vm.showDetailView(_vm.index)},"focus":function($event){return _vm.showDetailView(_vm.index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.switchFocus()}}},[(_vm.result)?_c('v-container',{staticClass:"pa-2",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 mb-1"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"11"}},[_c('p',{staticClass:"pa-0 ma-0"},[(_vm.result.company_name)?_c('span',[_vm._v(_vm._s(_vm.result.company_name))]):_c('span',[_vm._v(_vm._s(_vm.result.Company.name))])]),(_vm.result.job_name)?_c('h2',{staticClass:"ma-0 primaryText encodeFont"},[_vm._v(" "+_vm._s(_vm.result.job_name)+" ")]):(_vm.result.Job_name)?_c('h2',{staticClass:"ma-0 primaryText encodeFont"},[_vm._v(" "+_vm._s(_vm.result.Job_name.name)+" ")]):_vm._e()])],1),_c('v-row',{staticClass:"pa-0 ma-0"},[(_vm.result.Company && _vm.result.Company.logo)?_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"imageContain",staticStyle:{"max-height":"80px","min-height":"100%"},attrs:{"src":_vm.url + _vm.result.Company.logo}})],1):(_vm.result.company_logo)?_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4"}},[_c('v-img',{staticClass:"imageContain",staticStyle:{"max-height":"80px","min-height":"100%"},attrs:{"src":_vm.getLogoUrl(_vm.result.company_logo)}})],1):_vm._e(),_c('v-col',{staticClass:"py-0 pr-2",class:{
          'px-4':
            (_vm.result.Company && _vm.result.Company.logo) || _vm.result.company_logo,
          'pl-0': !(
            (_vm.result.Company && _vm.result.Company.logo) ||
            _vm.result.company_logo
          ),
        },attrs:{"cols":(_vm.result.Company && _vm.result.Company.logo) ||
            (_vm.result.company_logo ? 7 : 11)}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-weight":"bold","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.result.title)+" ")]),_c('p',{staticClass:"pa-0 ma-0"},[_c('v-icon',{staticStyle:{"margin-left":"-3px"}},[_vm._v("mdi-map-marker")]),(_vm.result.company_address)?_c('span',[_vm._v(_vm._s(_vm.result.mapBox_city ? _vm.result.mapBox_city : _vm.result.company_address.city))]):_c('span',[_vm._v(_vm._s(_vm.result.Company.Address.City.city))])],1)]),_c('v-col',{staticClass:"pr-1 pt-2",staticStyle:{"position":"absolute","right":"0px","top":"0px"},attrs:{"cols":"auto"}},[(_vm.result && _vm.result.Company && _vm.result.Company.locked)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")])]}}],null,false,774419492)},[_c('span',[_vm._v("Unternehmen gesperrt, Stellenanzeige in Suche nicht sichtbar")])]):_vm._e(),(_vm.result.published && _vm.showPublished)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye ")])]}}],null,false,2517779542)},[_c('span',[_vm._v("Stelle öffentlich")])]):(
            _vm.showPublished &&
              !_vm.result.published &&
              _vm.result.availability_end_date &&
              new Date(_vm.result.availability_end_date).toLocaleString() <
                new Date().toLocaleString()
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"gray"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-off ")])]}}],null,false,3517020341)},[_c('span',[_vm._v("Stelle abgelaufen")])]):(_vm.showPublished && !_vm.result.published)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-off ")])]}}],null,false,1512825460)},[_c('span',[_vm._v("Stelle privat")])]):_vm._e(),(_vm.resultInLocalStorage)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"width":"32px"},attrs:{"text":""},on:{"click":function($event){return _vm.removeFromFavorites($event)}}},[_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-star ")])],1)]}}],null,false,1290288277)},[_c('span',[_vm._v("Nicht mehr merken")])])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"width":"32px"},attrs:{"text":""},on:{"click":function($event){return _vm.addToFavorites($event)}}},[_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-star-outline ")])],1)]}}],null,false,616627122)},[_c('span',[_vm._v("Merken")])])],1),(_vm.result.verified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"width":"32px"},attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle ")])]}}],null,false,149378902)},[_c('span',[_vm._v("Verifiziert")])]):_vm._e()],1)],1),_c('v-row',{staticClass:"pb-4 px-4"},[_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-chip',{style:({
            'background-color':
              _vm.result.job_type == 'Praktikum'
                ? 'var(--v-primary-base) !important'
                : 'var(--v-accent-base) !important',
            color:
              _vm.result.job_type == 'Praktikum'
                ? 'white !important'
                : 'black !important',
          })},[_vm._v(" "+_vm._s(_vm.result.job_type)+" ")]),_c('v-chip',[_vm._v(" Beginn: "+_vm._s(_vm._f("moment")(_vm.result.job_start,"DD.MM.YY"))+" ")]),(_vm.result.job_end)?_c('v-chip',[_vm._v(" Bis: "+_vm._s(_vm._f("moment")(_vm.result.job_end,"DD.MM.YY"))+" ")]):_c('v-chip',[_vm._v(" Bis: -- ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }