var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('v-card',{staticClass:"smallFont focusable pa-2",staticStyle:{"height":"100%"},attrs:{"ripple":!_vm.blacklistView,"flat":""},on:{"click":function($event){return _vm.showDetailView()},"focus":function($event){return _vm.showDetailView()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.switchFocus()}}},[_c('v-container',{staticClass:"pa-2",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"4"}},[(_vm.data.logo)?_c('v-img',{staticClass:"imageContain",staticStyle:{"max-height":"80px","min-height":"100%"},attrs:{"src":_vm.getImgSrc}}):_c('v-img',{staticClass:"imageCover",staticStyle:{"max-height":"80px","min-height":"100%"},attrs:{"src":require("../../../assets/images/noImg.svg")}})],1),_c('v-col',{staticClass:"px-4 py-0",attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.data.Address.City.city.city ? _vm.data.Address.City.city.city : _vm.data.Address.City.city))]),_c('h2',{staticClass:"pa-0 ma-0 encodeFont"},[_vm._v(_vm._s(_vm.data.name))]),(_vm.showAddress)?_c('div',[_vm._v(" "+_vm._s(_vm.data.owner)+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.data.Address.City.postcode)+" "+_vm._s(_vm.data.Address.City.city)),_c('br'),_vm._v(" "+_vm._s(_vm.data.Address.street)+" "+_vm._s(_vm.data.Address.additional)+" ")]):_vm._e()]),_c('v-col',{staticStyle:{"position":"absolute","right":"0px","top":"0px"},attrs:{"cols":"auto"}},[(_vm.data.external)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-import ")])]}}],null,false,2390811512)},[_c('span',[_vm._v("Unternehmen von externer Börse. Kein Registrierter Nutzeraccount")])]):_vm._e(),(_vm.data.locked && !_vm.blacklistView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-cancel ")])]}}],null,false,3184567427)},[_c('span',[_vm._v("Blockiert (Stellenanzeigen nicht mehr in Suche)")])]):_vm._e(),(_vm.data.locked && _vm.blacklistView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.unlockCompany}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-open-variant-outline ")])]}}],null,false,424834322)},[_c('span',[_vm._v("Unternehmen freigeben")])]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }