<template>
  <div :id="'imagePreview_' + imageData.id" style="width: 100%">
    <div
      style="width: 100%"
      class="pa-0"
      :style="{ opacity: imageData.visible ? 1 : 0.5 }"
    >
      <v-container fluid class="pb-0">
        <v-row
          style="
            background-color: var(--v-primary-base);
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
          "
        >
          <v-spacer />
          <v-col cols="auto" @click="switchVisability()">
            <v-icon style="color: white" v-if="imageData.visible">mdi-eye</v-icon>
            <v-icon style="color: white" v-else>mdi-eye-off</v-icon>
          </v-col>
          <v-col cols="auto" @click="showDeletionWarning()"
            ><v-icon style="color: white">mdi-delete</v-icon></v-col
          >
        </v-row>
        <v-row
          style="
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
          "
        >
          <v-col class="pa-0" style="position: relative">
            <div
              style="
                background-size: cover;
                background-position: center;
                aspect-ratio: 16 / 9;
                border-bottom-left-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
              "
              :style="{ 'background-image': 'url(' + imageData.file + ')' }"
            ></div>
            <img
              src="../../../assets/images/mainPage.png"
              style="width: inherit; position: absolute; top: 0; left: 0"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "imagePreviewCard",

  props: ["imageData"],

  methods: {
    switchVisability() {
      this.$emit("switchVisability", this.imageData);
    },
    showDeletionWarning() {
      this.$emit("showDeletionWarning", this.imageData);
    },
  },
};
</script>
