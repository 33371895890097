<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="py-1 chartHeader" style="flex-grow: 0">
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>
    <JSCharting
      v-if="searchTerms"
      ref="stChart"
      :options="searchTermOptions"
      style="flex-grow: 1"
      class="pa-2"
      @rendered="fillChart"
    />
    <div
      v-else
      style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
    >
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import JSCharting from "jscharting-vue";

export default {
  name: "SearchTermChart",
  props: ["searchTerms", "label"],

  data() {
    return {
      searchTermOptions: {
        type: "column solid",
        debug: false,
        xAxis: {
          visible: false,
        },
        legend: {
          visible: false,
        },
        defaultPoint: {
          outline: {
            width: 0,
          },
          states: {
            hover: {
              fill: "rgba(00,10,09,1)",
            },
          },
          focusGlow: false,
        },
        series: [
          {
            name: "Suchbegriffe",
          },
        ],
      },
      greyColors: [
        "rgb(110,110,110)",
        "rgb(120,120,120)",
        "rgb(130,130,130)",
        "rgb(140,140,140)",
        "rgb(150,150,150)",
        "rgb(160,160,160)",
        "rgb(170,170,170)",
        "rgb(180,180,180)",
        "rgb(190,190,190)",
        "rgb(200,200,200)",
        "rgb(210,210,210)",
        "rgb(220,220,220)",
        "rgb(230,230,230)",
      ],
      points: [],
    };
  },
  components: {
    JSCharting,
  },
  watch: {
    "$vuetify.breakpoint.name"() {
      this.fillChart();
    },
  },
  methods: {
    searchTermNumbers() {
      let points = [];
      for (let i = 0; i < this.searchTerms.length; i++) {
        points.push({
          x: this.searchTerms[i][0].split("/")[0],
          y: this.searchTerms[i][1],
          focusGlow: {
            visible: false,
            width: 5,
          },
          hoverAction: "highlightPoint",
          color: this.greyColors[0],
          tooltip: `${this.searchTerms[i][0]}<br>%yValue Suchen<br>(%PercentOfTotal%)`,
          label: this.$vuetify.breakpoint.mdAndUp
            ? {
                text: `${this.searchTerms[i][0].split("/")[0]}`,
                autoHide: true,
                color: "#e1e1e1",
                rotate: -90,
                verticalAlign: "bottom",
                placement: "inside",
                style_fontSize: this.searchTerms[i][1] < 50 ? "12px" : "14px",
              }
            : {},
        });
      }

      return points;
    },
    fillChart() {
      this.points = this.searchTermNumbers();
      setTimeout(() => {
        let chart = this.$refs.stChart.instance;
        chart.series().options({ points: this.points });
      }, 250);
    },
  },
};
</script>
