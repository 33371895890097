<template>
  <div id="brafoWorlds">
    <v-expansion-panels v-model="panel" multiple flat>
      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header
          ><h2 class="encodeFont">
            Lebenswelt I - Mensch und Naturtechnik
          </h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            @click="selectField(0)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 1:Fertigen, verarbeiten und reparieren"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/1.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1"> Fertigen, verarbeiten und reparieren </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[0]"
                tabindex="0"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(1)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 2:Pflanzen anbauen oder Tiere züchten"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/2.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1"> Pflanzen anbauen oder Tiere züchten </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[1]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(2)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 3: Recycling, Rohstoffe gewinnen, entsorgen und reinigen"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/3.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Recycling, Rohstoffe gewinnen, entsorgen und reinigen
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[2]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header
          ><h2 class="encodeFont">
            Lebenswelt II - Mensch und Mitmenschen
          </h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            @click="selectField(3)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 4: Gesundheitlich oder sozial helfen und pflegen; Medizinisch oder kosmetisch
            behandeln"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/4.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Gesundheitlich oder sozial helfen und pflegen, Medizinisch oder kosmetisch
              behandeln
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[3]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(4)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 5: Erziehen, ausbilden und lehren"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/5.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1"> Erziehen, ausbilden und lehren </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[4]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(5)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 6: Sichern und schützen oder den Straßenverkehr regeln"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/6.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Sichern und schützen oder den Straßenverkehr regeln
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[5]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header
          ><h2 class="encodeFont">
            Lebenswelt III - Mensch und Kultur
          </h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            @click="selectField(6)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 7: Werben, Marketing und Öffentlichkeitsarbeit"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/7.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Werben, Marketing und Öffentlichkeitsarbeit
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[6]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(7)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 8: Bewirten, beherbergen und Speisen bereiten"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/8.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Bewirten, beherbergen und Speisen bereiten
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[7]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(8)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 9: Künstlerisch, journalistisch und unterhaltend tätig sein"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/9.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Künstlerisch, journalistisch und unterhaltend tätig sein
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[8]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header
          ><h2 class="encodeFont">
            Lebenswelt IV - Mensch und Information/Wissen
          </h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            @click="selectField(9)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 10: EDV oder das Erstellen von Zeichnungen, beraten und informieren"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/10.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              EDV oder das Erstellen von Zeichnungen, beraten und informieren
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[9]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(10)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 11: Einkaufen oder verkaufen, kassieren, sortieren, packen und beladen"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/11.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Einkaufen oder verkaufen, kassieren, sortieren, packen und beladen
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[10]"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            @click="selectField(11)"
            class="verticalAlign"
            aria-label="Tätigkeitsfeld 12: Messen, prüfen, erproben und kontrollieren"
          >
            <v-col cols="auto" class="pr-0 py-1" style="align-self: center">
              <img src="../../assets/images/icons/12.svg" class="svgIcon" />
            </v-col>
            <v-col cols="8" class="py-1">
              Messen, prüfen, erproben und kontrollieren
            </v-col>
            <v-col cols="2">
              <v-checkbox
                @input="preventCheckboxDefault"
                class="checkboxRight hideInputMessage ma-0"
                :value="fieldInputs[11]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn large block class="mt-4" @click="search"> Suchen </v-btn>
  </div>
</template>

<script>
export default {
  name: "brafoSearch",

  data() {
    return {
      panel: [0, 1, 2, 3],

      fieldInputs: [],
    };
  },

  watch: {},

  methods: {
    search() {
      this.$emit("search");
    },
    selectField(number) {
      this.fieldInputs[number] = !this.fieldInputs[number];
      this.$forceUpdate();

      this.$SearchFilterHandler.setSelectedBrafos(this.fieldInputs);
    },
    preventCheckboxDefault(e){
      e.preventDefault();
    }
  },

  mounted() {
    let checkboxes = document.getElementById("brafoWorlds").getElementsByTagName("input");

    for (let i = 0; i < checkboxes.length; i++) {
      this.fieldInputs[i] = false;
      this.$forceUpdate();

      checkboxes[i].addEventListener(
        "keypress",
        function (e) {
          if (e.key === "Enter") {
            this.fieldInputs[i] = !this.fieldInputs[i];

            this.$forceUpdate();
          }
        }.bind(this)
      );
    }

    this.fieldInputs = this.$SearchFilterHandler.searchFilters.brafoSelections;
  },
};
</script>
