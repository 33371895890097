<template>
  <div class="mb-3" id="blacklistList">
    <v-container
      fluid
      style="
        margin: 0 auto;
        height: calc(100vh - 190px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
      "
      class="scrollable"
    >
      <v-row class="mb-0" style="flex-grow: 0;">
        <v-col :cols="isMobile ? 12 : 6" style="display: flex" 
        class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="12" class="pa-0 pr-1">
                <v-card flat >
                  <v-card-title class="encodeFont pa-0 pb-1">
                    <v-text-field
                      hide-details
                      v-model="searchString"
                      class="px-4 hideInputMessage"
                      append-icon="mdi-magnify"
                      label="Firmenname eingeben"
                    >
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ totalCompanies }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row style="flex-grow: 0;">
        <v-col
          :style="{
            'padding-left': index % 2 == 1 ? '10px' : '0px',
            'padding-right': index % 2 == 0 ? '6px' : '0px',
          }"
          :cols="isMobile ? 12 : 6"
          v-for="(company, index) in companies"
          :key="company.id + '_card'"
          style="display: flex"
          class="pt-0 pb-4"
        >
          <companyCard
            :data="company"
            :showAddress="true"
            :blacklistView="true"
            @unlock="showFreeCompany(company)"
          />
        </v-col>
      </v-row>

      <v-row style="flex-grow: 1"></v-row>
    </v-container>

    <v-container>
      <v-row class="mt-1 pa-0">
        <v-spacer />
        <v-col class="ma-0 pa-0" cols="5">
          <v-pagination
            class="mt-2 focusableNoBorder"
            v-model="page"
            :length="maxPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <v-overlay :value="overlay" :dark="false">
      <freeFromBlacklist
        @confirm="unlockCompany"
        @close="overlay = false"
        :company="currCompany"
      />
    </v-overlay>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import companyCard from "../../../components/Backoffice/Company/CompanyCard.vue";
import freeFromBlacklist from "../../../components/Backoffice/Editor/FreeFromBlacklist.vue";

const axios = require("axios");

export default {
  name: "companyBlacklist",

  props: ["width", "height", "search"],

  data() {
    return {
      companies: [],

      overlay: false,
      currCompany: undefined,

      loading: false,

      maxPages: undefined,
      page: 1,

      searchString: undefined,
      totalCompanies: undefined,
    };
  },

  watch: {
    page() {
      this.getCompanyData();
    },
    searchString() {
      if (this.searchString && this.searchString.length >= 3) {
        if (this.page == 1) {
          this.getCompanyData();
        } else {
          this.page = 1;
        }
      } else {
        this.getCompanyData();
      }
    },
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  components: {
    companyCard,
    freeFromBlacklist,
  },

  methods: {
    showFreeCompany(company) {
      this.currCompany = company;
      this.overlay = true;
    },
    unlockCompany(companyData) {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "companies/patch/" + companyData.id, {
          id: companyData.id,
          locked: false,
        })
        .then(() => {
          for (let i = 0; i < this.companies.length; i++) {
            if (this.companies[i].id == companyData.id) {
              this.companies.splice(i, 1);

              this.overlay = false;
              this.currCompany = undefined;

              break;
            }
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
    getCompanyData() {
      this.loading = true;

      let searchString = "?page=" + this.page;
      if (this.searchString) {
        searchString += "&search=" + this.searchString.replace(/&/g, "%26");
      }

      axios
        .get(axios.defaults.baseURL + "companies/blacklist/" + searchString)
        .then((resp) => {
          this.companies = resp.data.results;
          this.totalCompanies = resp.data.count

          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getCompanyData();
  },
};
</script>
