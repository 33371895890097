<template>
  <div
    style="height: 100%; display: flex; flex-direction: column; overflow-x: auto; overflow-y: hidden"
  >
    <div class="py-1 chartHeader" style="flex-grow: 0;">
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>
    <div
      style="height: 100%; display: flex; flex-direction: column"
      :style="{ width: isMobile ? '1200px' : 'unset' }"
    >
      <JSCharting
        v-if="clicks"
        ref="clicksChart"
        :options="clicksOptions"
        style="flex-grow: 1;"
        @rendered="fillChart"
      />
      <div
        v-else
        style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
      >
        <v-progress-circular indeterminate color="accent"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import JSCharting from "jscharting-vue";

export default {
  name: "ClicksChart",
  props: ["clicks", "label"],

  data() {
    return {
      clicksOptions: {
        type: "line",
        debug: false,
        legend: {
          template: "%icon %xValue",
          position: "inside right bottom",
          visible: false,
        },
        xAxis: {
          formatString: "dd.MM.yy",

          scale: {
            interval: {
              unit: "day",
              multiplier: 3,
            },
            minorInterval: {
              unit: "day",
              multiplier: 3,
            },
            type: "time",
            //   interval: {
            //     unit: 'week',
            //     multiplier: 1
            //   }
          },
          // visible: false
        },
        yAxis: {
          scale: {
            range: [0],
          },
        },
        defaultPoint: {
          outline: {
            width: 0,
          },
          marker: {
            size: 8,
            visible: true,
          },
        },
        defaultSeries: {
          mouseTracking_enabled: true,
          line_width: 3,
        },
        series: [
          {
            name: "Clicks",
            defaultPoint_tooltip:
              "%xValue<br>%yValue Aufrufe<br>(%PercentOfTotal%)",
            color: this.$vuetify.theme.defaults.light.primary,
            states: {
              hover: {
                line_width: 3,
              },
            },
          },
        ],
      },
      greyColors: [
        "rgb(110,110,110)",
        "rgb(115,115,115)",
        "rgb(130,130,130)",
        "rgb(125,125,125)",
        "rgb(150,150,150)",
        "rgb(135,135,135)",
        "rgb(170,170,170)",
        "rgb(145,145,145)",
        "rgb(190,190,190)",
        "rgb(155,155,155)",
        "rgb(160,160,160)",
        "rgb(165,165,165)",
        "rgb(170,170,170)",
      ],
      points: undefined,
    };
  },
  components: {
    JSCharting,
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    clicksNumbers() {
      let points = [];
      for (const [key, value] of Object.entries(this.clicks)) {
        points.push({
          x: key,
          y: value,
        });
      }

      return points;
    },
    fillChart() {
      this.points = this.clicksNumbers();
      setTimeout(() => {
        let chart = this.$refs.clicksChart.instance;
        chart.series().options({ points: this.points });
      }, 250);
    },
  },
  // mounted() {
  //   this.fillChart();
  // },
};
</script>
