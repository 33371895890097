<template>
  <v-container fluid>
    <v-row class="pt-4 pl-8">
      <v-col
        :cols="'4'"
        style="display: flex; align-items: center; justify-content: flex-start"
        class="pl-8"
      >
        <img
          :src="getImg()"
          width="100%"
          style="min-width: 40px; max-width: 80px"
        />
      </v-col>

      <v-col style="display: flex; align-items: center" class="pl-0">
        <v-container>
          <v-row>
            <v-col class="pa-0 pr-4" cols="10">
              <h2
                v-html="apiName"
                style="color: #909090"
                class="encodeFont"
              ></h2>
            </v-col>
          </v-row>

          <v-row class="pa-0">
            <v-col class="pa-0 mr-4" cols="8">
              <h2 style="color: var(--v-primary-base); font-size: 36px" class="encodeFont">
                {{ num["internships"] + num["apprenticeships"] }}
              </h2>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0 mt-2 px-12">
      <v-col class="pa-0" cols="4">
        <span
          style="color: #909090"
        >
          <strong>{{ num["internships"] }}</strong>
        </span>
      </v-col>
      <v-spacer />
      <v-col class="pa-0 text-right">
        <span
          style="color: #909090"
        >
          <strong>{{ num["apprenticeships"] }}</strong>
        </span>
      </v-col>
    </v-row>

    <v-row class="pt-0 mt-0">
      <v-spacer></v-spacer>
      <v-col class="pb-1 pt-0" :cols="this.$vuetify.breakpoint.mdAndDown ? 12 : 10">
        <v-progress-linear
          :value="
            (num['internships'] /
              (num['internships'] + num['apprenticeships'])) *
              100
          "
          height="16"
          style="border-radius: 12px"
          color="primary"
          background-color="accent"
        >
        </v-progress-linear>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <!-- <v-row class="px-12 pt-2">
      <v-col class="pt-1">
        <v-progress-linear
          :value="(num['apprenticeships'] / total['apprenticeships']) * 100"
          height="16"
          style="border-radius: 12px"
          color="accent"
        >
          <template v-slot:default="{ value }">
            <strong>{{ value.toFixed(2) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row> -->
    <div v-if="stelle == 'Insgesamt'" style="height: 40px"></div>
  </v-container>
</template>

<script>
export default {
  props: ["stelle", "num", "total", "dashboard", "icon"],
  name: "ExternalStat",

  data() {
    return {};
  },
  computed: {
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "36px";
        case "sm":
          return "64px";
        case "md":
          return "64px";
        case "lg":
          return "88px";
        case "xl":
          return "150px";
        default:
          return "128px";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    imageCols() {
      if (this.dashboard) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 2;
          case "sm":
            return 2;
          case "md":
            return 3;
          case "lg":
            return 2;
          case "xl":
            return 2;
          default:
            return 2;
        }
      }
      return 4;
    },
    apiName(){
      let apiName = this.stelle
      if(apiName == "Handwerkskammer Halle"){
        apiName = "Hand&shy;werks&shy;kammer Halle"
      }
      if(apiName == "Insgesamt"){
        apiName = "Ins&shy;gesamt"
      }
      if(apiName == "Zukunftschecker"){
        apiName = "Zukunfts&shy;checker"
      }
      return apiName
    }
  },
  methods: {
    getImg(){
      let img = undefined
      try{
        img = require('@/assets/images/' + this.icon)
      }
      catch{
        img = require('@/assets/images/placeholder_api.svg')
      }
      return img
    }
  }
};
</script>
