<template>
  <div>
    <v-snackbar v-model="snackbar" :multi-line="true" :timeout="-1" top>
      <b style="font-size:larger;"> Fehler </b><br /><br />
      Bei der Bearbeitung Ihrer Anfrage ist ein unerwarteter Fehler aufgetreten. Bitte
      prüfen Sie Ihre Internetverbindung und versuchen Sie es dann erneut.
      <template v-slot:action="{ attrs }">
        <v-btn class="py-3" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "errorDisplay",

  props: ["showErrorDisplay"],

  data() {
    return {
      snackbar: false,
    };
  },

  watch: {
    showErrorDisplay() {
      this.snackbar = true;
    },
  },
};
</script>
