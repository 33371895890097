<template>
  <v-card flat :style="{display: events.length == 0 ? 'none' : 'block'}">
    <!-- <h2 class="encodeFont pt-2" style="text-align:center">Aktuelle Events</h2>
      <hr class="my-2" style="border-color: var(--v-primary-base) !important; opacity: 0.5;"> -->
    <v-carousel v-model="model" id="eventCarousel">

      <v-carousel-item @keyup.enter="switchFocus()" v-for="e in events" :key="e.id" @focus="emitSelect(e.id)" class="focusable" tabindex="0">
        <v-container :id="'eventCard_' + e.id">
          <v-row>
            <v-col cols="12" @click="emitSelect(e.id)">
              <eventCardComponent :eventData="e" />
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import eventCardComponent from "./EventCard.vue";

export default {
  name: "eventCarousel",

  props: ["events"],

  data: () => ({
    model: 0,
  }),

  components: {
    eventCardComponent,
  },

  watch: {
    model(){
        if(!this.isMobile){
            this.emitSelect(this.events[this.model].id);
        }
    }
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    switchFocus(){
      this.$emit("switchFocus");
    },
    emitSelect(eventID) {
      this.$emit("select", eventID)
    },
  },
};
</script>
