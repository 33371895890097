<template>
  <div>
    <v-container class="pa-4 mt-6" :fluid="isMobile" fill-height>
      <v-row>
        <!-- <v-spacer v-if="!isMobile" /> -->
        <v-col :cols="cols * 1.5">
          <v-card class="pa-4" flat style="height: 100%">
            <v-container>
              <v-row>
                <v-col :cols="isMobile ? 12 : 6">
                  <a href="https://www.bmbf.de/bmbf/de/home/home_node.html" target="_blank">
                  <v-img
                    src="../../assets/images/logo_links.jpg"
                    style="height: 150px"
                    class="imageContain"
                  >
                  </v-img>
                  </a>
                </v-col>
                <v-col :cols="isMobile ? 12 : 6">
                  <a href="https://www.bildungsketten.de/bildungsketten/de/home/home_node.html" target="_blank">
                  <v-img
                    src="../../assets/images/logo_rechts.jpg"
                    style="height: 150px"
                    class="imageContain"
                  >
                  </v-img>
                  </a>
                </v-col>
              </v-row>
            </v-container>

            <hr class="mt-2" />
            <v-card-title class="pl-0">
              Berufsorientierung für Schülerinnen und Schüler ab der 8. Klasse
            </v-card-title>

            Im Zuge der Bildungsketten-Vereinbarung haben sich die vier gewerblichen
            Kammern in Sachsen-Anhalt zum Ziel gesetzt, das Matching zwischen
            Ausbildungsinteressierten und Ausbildungsunternehmen zu verbessern und die
            Qualität der dualen Berufsausbildung zu erhöhen. <br />
            <br />
            Die Entwicklung von Hallo-Beruf, einer Suchplattform für Praktikums- und
            Ausbildungsplätze für Schülerinnen und Schüler ab der 8. Klasse, soll dazu
            beitragen, die Suche nach Praktikums- und Ausbildungsplätzen zu vereinfachen,
            die Nachwuchsgewinnung für Unternehmen zu erleichtern und die Zusammenarbeit
            zwischen Schulen und Unternehmen zu stärken. Falls du also ein
            Schülerpraktikum in deiner Nähe suchst, weil du deinen Traumberuf ausprobieren
            oder dir noch nicht richtig sicher bist, was du beruflich später machen
            möchtest, dann bist du auf Hallo Beruf genau richtig. Auch wenn du schon einen
            Schritt weiter bist und für deinen Ausbildungswunsch das passende Unternehmen
            suchst, wirst du hier ganz sicher fündig.<br />
            <br />
            Die Initiative Bildungsketten wird gefördert vom Bundesministerium für Bildung
            und Forschung.
          </v-card>
        </v-col>
        <v-col :cols="cols * 1.5">
          <v-card class="pa-4" flat style="height: 100%">
            <v-img
              src="../../assets/images/BRAFO-Logo.jpg"
              style="height: 150px"
              class="imageContain"
            >
            </v-img>
            <hr class="mt-2" />
            <v-card-title style="word-break: break-word !important" class="pl-0">
              BRAFO - Berufswahl Richtig Angehen Frühzeitig Orientieren
            </v-card-title>

            <a
              href="https://ms.sachsen-anhalt.de/themen/arbeit/berufsorientierung-ausbildung/brafo-das-landesberufsbildungsprogramm"
              target="_blank"
              >BRAFO (Berufswahl Richtig Angehen Frühzeitig Orientieren)</a
            >
            bietet Schülerinnen und Schülern die Möglichkeit verschiedenste berufliche
            Tätigkeiten zu testen und herauszufinden welche davon am besten zu den eigenen
            Stärken und Interessen passen, um so die Ausbildungs- und Berufswahl zu
            erleichtern.<br /><br />
            Du weißt schon in welcher Lebenswelt du einmal arbeiten möchtest? Oder suchst
            du ein Praktikum, um ein Tätigkeitsfeld näher kennen zu lernen? Unsere Suche
            nach BRAFO Tätigkeitsfeldern unterstützt dich genau dabei!<br /><br />
            <v-btn
              @click="gotoMainPageID()"
              large
              block
              class="primaryButton py-3"
              style="height: unset; margin: 0 auto"
              >Jetzt Suchen</v-btn
            >
          </v-card>
        </v-col>
        <!-- <v-spacer v-if="!isMobile" /> -->
      </v-row>

      <v-row>
        <!-- <v-spacer v-if="!isMobile && !isMedium" /> -->
        <v-col :cols="cols * 3">
          <v-card class="pa-4" flat style="height: 100%">
            <v-card-title style="word-break: break-word !important" class="pl-0">
              Hallo-Beruf - Partnerbörsen
            </v-card-title>
            Wir freuen uns über die Zusammenarbeit der regionalen Stellenbörsen, der
            Bundesagentur für Arbeit und den Kammern der IHK und HWK, die euch dabei
            helfen werden euer Traumpraktikum oder eure Traumausbildung zu finden!
            <br />
            Neben zahlreichen Stellenangeboten findet ihr bei uns selbstverständlich auch
            diverse Events zur Berufsorientierung. Hier könnte ihr neue Berufe entdecken
            und euch direkt vor Ort austesten.
            <br /><br />

            <v-container>
              <v-row>
                <v-col :cols="logoCols">
                  <a href="https://hwkhalle.de/" target="_blank">
                    <v-img
                      src="../../assets/images/HWK_Halle_4C.svg"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
                <v-col :cols="logoCols">
                  <a href="https://www.hwk-magdeburg.de/" target="_blank">
                    <v-img
                      src="../../assets/images/logo3.png"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
                <v-col :cols="logoCols">
                  <a
                    href="https://www.ferientage-in-unternehmen.de/wisl_s-cms/_unternehmen/0/Unternehmen.html?submode=filterall&filter_main=&filter_region=Bitterfeld-Wolfen&filter_berufe"
                    target="_blank"
                  >
                    <v-img
                      src="../../assets/images/ferientage_logo.png"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
                <v-col :cols="logoCols">
                  <a href="https://www.arbeitsagentur.de/jobsuche/" target="_blank">
                    <v-img
                      src="../../assets/images/ba-logo.svg"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
                <v-col :cols="logoCols">
                  <a href="https://www.ihk.de/halle" target="_blank" style="width: 50%">
                    <v-img
                      src="../../assets/images/logo2.png"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
                <v-col :cols="logoCols">
                  <a href="https://www.ihk.de/magdeburg" target="_blank">
                    <v-img
                      src="../../assets/images/logo1.png"
                      style="height: 150px"
                      class="imageContain"
                    >
                    </v-img
                  ></a>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <!-- <v-spacer v-if="!isMobile && !isMedium" /> -->
      </v-row>
      <v-row>
      <v-spacer v-if="!isMobile && !isMedium" />
        <v-col :cols="cols * 3">
          <video style="width: 100%" controls preload="none" poster="../../assets/images/video_posters/IHK_Halle_2023.png">
            <source src="../../assets/videos/IHK_Halle_11154_V03.mp4" type="video/mp4" />
            Ihr Browser unterstützt das Video-Tag nicht.
          </video>
        </v-col>
      <v-spacer v-if="!isMobile && !isMedium" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "newsAndInfo",

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        case "lg":
          return "4";
        case "xl":
          return "4";
        default:
          return "6";
      }
    },
    logoCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "6";
        case "sm":
          return "6";
        case "md":
          return "6";
        case "lg":
          return "4";
        case "xl":
          return "4";
        default:
          return "6";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    isMedium() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    gotoMainPageID() {
      var element = document.getElementById("mainPage");
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>
