<template>
  <div>
    <v-card class="pa-8" style="max-width: 90vw">
      <v-btn
        @click="cancel()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-container>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <h2 class="encodeFont">
              Möchten Sie, dass {{ item }}
              <span style="color:var(--v-primary-base)" v-if="data">{{ data }}</span> wirklich gelöscht wird?
            </h2>
          </v-col>
        </v-row>
        <v-row v-if="additional" class="pa-0 ma-0 pt-8"> 
          <v-col class="pa-0 ma-0">
            <p><b>{{additional}}</b></p>
          </v-col>
        </v-row>
        <br>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <p class="mt-2 red--text">Diese Aktion kann nicht rückgängig gemacht werden.</p>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <p>Sind Sie sicher?</p>
          </v-col>
        </v-row>
        <br />
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0" :class="{'pr-2': !isMobile, 'mb-3': isMobile}" :cols="isMobile ? 12 : 8">
            <v-btn
              @click="cancel()"
              elevation="0"
              block
              large
              class="primaryButton py-3"
              :class="{'my-1': isMobile}"
            >
              Nein, zurück.
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col class="pa-0 ma-0" :class="{'pl-2': !isMobile}" :cols="isMobile ? 12 : 4">
            <v-btn @click="confirm()" elevation="0" block large class="py-3 primaryOnWhiteButton lightOutline" :class="{'pa-1': isMobile, 'mt-3': isMobile}">
              Ja, löschen!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">Fokus zurück zu "Schließen"</div>
  </div>
</template>

<script>
export default {
  name: "deletionWarning",

  props: ["data", "item", "additional"],

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    resetFocus(e){
      e.stopPropagation();
      e.preventDefault();

       document.getElementById("closeButton").focus();
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },

  mounted(){
    document.getElementById("closeButton").focus();
  }
};
</script>
