<template>
  <v-card flat :style="{ opacity: eventData && eventData.expired ? 0.5 : 1 }">
    <v-container>
      <v-row>
        <v-col cols="4" style="display: flex; align-items: center">
          <v-img
            v-if="eventData.logo"
            :src="eventData.logo"
            :alt="'Eventlogo für ' + eventData.title"
          ></v-img>
          <v-img
            v-else
            class="imageCover"
            src="../../../assets/images/noImg.svg"
            :alt="'Platzhalterlogo für ' + eventData.title"
          ></v-img>
        </v-col>
        <v-col
          :cols="7"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div>
            <h2 class="encodeFont mb-3">
              {{ eventData.title }}
              <div
                v-if="editView"
                style="position: absolute; top: 0; right: 0"
                class="mt-2 mr-2"
              >
                <v-tooltip bottom v-if="eventData && eventData.expired">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                        color: var(--v-primary-base);
                      "
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                      tabindex="0"
                      >mdi-calendar-remove</v-icon
                    >
                  </template>
                  <span>Das Event ist abgelaufen</span>
                </v-tooltip>

                <v-tooltip bottom v-else-if="eventData.status == 'Akzeptiert'">
                  <template v-slot:activator="{ on, attrs }"
                    ><v-icon
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                        color: var(--v-primary-base);
                      "
                      class="pr-2"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-check</v-icon
                    >
                  </template>
                  <span>Veröffentlicht</span>
                </v-tooltip>
                <v-tooltip bottom v-else-if="eventData.status == 'In Bearbeitung'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon
                        style="
                          color: var(--v-primary-base);
                          text-align: center;
                          margin: 0 auto;
                          padding-right: 0 !important;
                        "
                        class="pr-2"
                        >mdi-clock-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Wird geprüft</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon
                        style="
                          color: var(--v-primary-base);
                          text-align: center;
                          margin: 0 auto;
                          padding-right: 0 !important;
                        "
                        class="pr-2"
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Abgelehnt</span>
                </v-tooltip>
              </div>
            </h2>
            <!-- <p class="shortTextDisplay encodeFont" v-html="eventData.description"></p> -->
          </div>
          <div v-if="!isMobile">
            <v-chip
              class="mr-2 mb-2"
              style="color: white; background-color: var(--v-secondary-base) !important"
              >Event</v-chip
            >
            <v-chip class="mr-2 mb-2"
              >Von: {{ eventData.start_date | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip class="mb-2">Bis: {{ eventData.end_date | moment("DD.MM.YY") }}</v-chip>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isMobile">
        <v-col cols="12">
          <div style="display: flex; justify-content: space-between">
            <v-chip
              class="mr-3"
              style="color: white; background-color: var(--v-secondary-base) !important"
              >Event</v-chip
            >
            <v-chip class="mr-3"
              >Von: {{ eventData.start_date | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip>Bis: {{ eventData.end_date | moment("DD.MM.YY") }}</v-chip>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "backofficeEventCard",

  props: ["eventData", "editView"],

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
};
</script>
