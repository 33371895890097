<template>
  <div
    id="companyView"
    style="width: 100%"
  >

  <router-view />

  </div>
</template>

<script>
export default {
  name: "editorCompanyList",

  data() {
    return {
    
    };
  },

  watch: {
  },

};
</script>
