var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"hiddenScroll",staticStyle:{"height":"calc(100vh - 68px)","position":"relative","overflow-y":"auto","width":"100%","max-width":"inherit"},style:({ width: _vm.smallDrawer ? '64px' : 'unset' })},[_c('v-navigation-drawer',{staticClass:"mt-16",staticStyle:{"background-color":"var(--v-theme-background))","position":"fixed","max-width":"inherit"},style:({
      width: _vm.smallDrawer ? 'unset' : '100%',
    }),attrs:{"permanent":"","absolute":"","mini-variant":_vm.smallDrawer}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{on:{"click":function($event){return _vm.changeDrawerSize()}}},[(_vm.smallDrawer)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"px-0",attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"background-color":"transparent"},attrs:{"flat":""}},[_c('div',{staticClass:"pb-1 pt-2 mt-3",staticStyle:{"display":"flex","align-items":"center"}},[_c('v-icon',{class:{ 'mr-2': !_vm.smallDrawer },staticStyle:{"margin-top":"2px","color":"darkgray"}},[_vm._v("mdi-menu")]),(!_vm.smallDrawer)?_c('b',[_vm._v("Menü")]):_vm._e()],1)])],1),(_vm.smallDrawer)?_c('v-spacer'):_vm._e()],1),_c('hr',{staticClass:"my-4",staticStyle:{"opacity":"0.3"}}),_vm._l((_vm.routeArray),function(r,index){return _c('div',{key:'route_' + index},[(!r.props.permission || _vm.$store.state.permission == r.props.permission)?_c('v-row',{staticClass:"mt-3"},[(_vm.smallDrawer)?_c('v-spacer'):_vm._e(),(
              r &&
              (r.props.displayInNavigation ||
                (r.props.displayOnActive && _vm.isActive(r))) &&
              !(r.props.hideOnActive && _vm.activeRoute.startsWith(r.props.hideOnActive))
            )?_c('v-col',{class:{ 'pb-0': _vm.smallDrawer, 'pl-0': !_vm.smallDrawer }},[_c('v-card',{staticClass:"pa-2 pl-1",style:({
                'background-color': _vm.smallDrawer
                  ? 'transparent'
                  : r.name == _vm.activeRoute || _vm.activeRoute.startsWith(r.name)
                  ? 'var(--v-accentButLighter-base)'
                  : 'white',
              }),attrs:{"flat":""},on:{"click":function($event){return _vm.gotoRoute(r)},"mousedown":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.openRouteInNewTab(r)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.smallDrawer)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{ 'mr-2': !_vm.smallDrawer },staticStyle:{"margin-top":"2px"},style:({
                        color: _vm.isActive(r) ? 'var(--v-primary-base)' : 'darkgray',
                      }),attrs:{"size":'x-large'}},'v-icon',attrs,false),on),[_vm._v("mdi-"+_vm._s(r.props.icon))])]}}],null,true)},[_c('span',{staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(r.props.displayName))])]):_c('v-icon',{staticClass:"ml-2",class:{ 'mr-2': !_vm.smallDrawer },staticStyle:{"margin-top":"2px"},style:({
                    color: _vm.isActive(r) ? 'var(--v-primary-base)' : 'darkgray',
                  }),attrs:{"size":'x-large'}},[_vm._v("mdi-"+_vm._s(r.props.icon))]),(_vm.isActive(r) && !_vm.smallDrawer)?_c('span',{staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap","display":"block"}},[_c('b',[_vm._v(_vm._s(r.props.displayName))])]):(!_vm.smallDrawer)?_c('span',{staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap"}},[_c('p',{staticClass:"mb-0",staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap","display":"block"}},[_vm._v(" "+_vm._s(r.props.displayName)+" ")])]):_vm._e()],1)]),(_vm.showChildren(r))?_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.smallDrawer ||
                  r.props.stayActive ||
                  (_vm.isActive(r) && r.children && r.children.length > 1)
                ),expression:"\n                  smallDrawer ||\n                  r.props.stayActive ||\n                  (isActive(r) && r.children && r.children.length > 1)\n                "}]},[_c('v-container',_vm._l((r.children),function(c,childIndex){return _c('v-row',{key:'route_child_' + childIndex,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoChild(c)},"mousedown":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.openChildInNewTab(c)}}},[(c.props.displayInNavigation)?_c('v-col',{class:{ 'pa-0': _vm.smallDrawer }},[_c('v-card',{staticClass:"pa-2",class:{ 'pr-0': _vm.smallDrawer, 'pl-1': _vm.smallDrawer },style:({
                          'background-color': _vm.smallDrawer
                            ? 'transparent'
                            : c.name == _vm.activeRoute || _vm.activeRoute.startsWith(c.name)
                            ? 'var(--v-accentButLighter-base)'
                            : 'white',
                        }),attrs:{"flat":""}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},style:({
                            'justify-content': _vm.smallDrawer ? 'center' : 'flex-start',
                          })},[(_vm.smallDrawer)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-0",class:{ 'pr-1': _vm.smallDrawer },staticStyle:{"margin-top":"2px"},style:({
                                  color:
                                    c.name == _vm.activeRoute ||
                                    _vm.activeRoute.startsWith(c.name)
                                      ? 'var(--v-primary-base)'
                                      : 'darkgray',
                                }),attrs:{"size":'x-large'}},'v-icon',attrs,false),on),[_vm._v("mdi-"+_vm._s(c.props.icon))])]}}],null,true)},[_c('span',{staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(c.props.displayName))])]):_c('v-icon',{class:{ 'mr-2': !_vm.smallDrawer },staticStyle:{"margin-top":"2px"},style:({
                              color:
                                c.name == _vm.activeRoute || _vm.activeRoute.startsWith(c.name)
                                  ? 'var(--v-primary-base)'
                                  : 'darkgray',
                            }),attrs:{"size":'x-large'}},[_vm._v("mdi-"+_vm._s(c.props.icon))]),(
                              !_vm.smallDrawer &&
                              (c.name == _vm.activeRoute || _vm.activeRoute.startsWith(c.name))
                            )?_c('b',{staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap"}},[_vm._v(_vm._s(c.props.displayName))]):(!_vm.smallDrawer)?_c('p',{staticClass:"mb-0",staticStyle:{"text-overflow":"ellipsis","width":"100%","overflow-x":"hidden","white-space":"nowrap"}},[_vm._v(" "+_vm._s(c.props.displayName)+" ")]):_vm._e()],1)])],1):_vm._e()],1)}),1)],1)]):_vm._e(),(_vm.smallDrawer && index != _vm.routeArray.length - 1)?_c('v-divider',{staticClass:"mt-1"}):_vm._e()],1):_vm._e(),(_vm.smallDrawer)?_c('v-spacer'):_vm._e()],1):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }