<template>
  <div>
    <v-card
      :style="{ width: cardWidth }"
      style="max-height: 90vh"
      class="scrollable px-8 py-12"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="editorForm">
        <v-container>
          <v-row class="mb-2">
            <v-col class="py-0">
              <h2 class="encodeFont" v-if="!isMobile">Redakteursinformationen</h2>
              <h2 class="encodeFont" v-else>
                Redakteurs- <br />
                informationen
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0 pl-3" :cols="12">
              <v-text-field outlined label="Rolle" v-model="permission" readonly disabled>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0 pr-3" :cols="isMobile ? 12 : 2">
              <v-select
                label="Anrede*"
                :rules="[required]"
                outlined
                v-model="salutation"
                :items="salutations"
              >
              </v-select>
            </v-col>
            <v-col class="pb-0 pr-3" :cols="isMobile ? 12 : 5">
              <v-text-field
                outlined
                label="Vorname*"
                v-model="firstName"
                :rules="[required, max30]"
              >
              </v-text-field>
            </v-col>
            <v-col class="pb-0 pl-3" :cols="isMobile ? 12 : 5">
              <v-text-field
                outlined
                label="Nachname*"
                v-model="lastName"
                :rules="[required, max30]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pb-0 pr-3"
              :cols="editorData && editorData.permission == 1 ? 11 : 12"
            >
              <v-text-field
                :disabled="editorData && editorData.permission == 1"
                outlined
                label="Email*"
                v-model="email"
                :rules="[max200]"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="editorData && editorData.permission == 1"
              class="pb-0 pl-3"
              style="display: flex; height: 68px; align-items: center"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary"
                    >mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Admin-Emails können hier nicht geändert werden. Bitte kontaktieren Sie
                  den roba.one Support</span
                >
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row v-if="!editorData">
            <v-spacer />
            <v-col cols="auto">
              Der Redakteur*in wird eine Email zur Registrierung zugesendet.
            </v-col>
            <v-spacer />
          </v-row>

          <v-row class="mt-2 py-2">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                block
                large
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                :class="{ 'pa-1': isMobile }"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                block
                large
                elevation="0"
                class="primaryButton py-3"
                :class="{ 'pa-1': isMobile }"
                @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { permissions } from "@/util/permissions";
const axios = require("axios");

export default {
  name: "editorEdit",
  props: ["editorData"],

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      permission: "",
      salutation: 0,

      required: (value) => !!value || "Pflichtfeld!",
      max30: (value) => (!!value && value.length <= 30) || "Max. 30 Zeichen!",
      max200: (value) => (!!value && value.length <= 200) || "Max. 200 Zeichen!",

      salutations: ["Divers", "Frau", "Herr"],

      loading: false,
    };
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "98vw";
        case "sm":
          return "90vw";
        case "md":
          return "80vw";
        case "lg":
          return "55vw";
        case "xl":
          return "45vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    close(resp) {
      this.$emit("close", resp);
    },
    saveData() {
      if(this.$refs.editorForm.validate()){
      this.loading = true;

      let formData = {
        first_name: this.firstName,
        last_name: this.lastName,
        salutation: this.salutation ? (this.salutations.indexOf(this.salutation) + 1) : 1,
      };

      if (this.editorData) {
        formData.id = this.editorData.id;

        if (
          !this.editorData.permission == permissions.ADMIN &&
          this.email != this.editorData.email
        ) {
          formData.email = this.email;
        }

        axios
          .patch(axios.defaults.baseURL + "editors/patch/" + this.editorData.id, formData)
          .then((resp) => {
            this.$emit("close", resp.data)
            })
          .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              console.log(e);
              this.$emit("error", "Eingaben ungültig.");
            });
          
      } else {
        formData.email = this.email;

        axios
          .post(axios.defaults.baseURL + "editorrequests/post/", formData)
          .then((resp) => {
            this.$emit("close", resp.data);
          })
          .catch((e) => {
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            this.$emit(
              "error",
              "Das Anlegen des Redakteurs ist fehlgeschlagen. Bitte prüfen Sie, ob der Account bereits vorhanden ist."
            );
            console.log(e);
          });
      }}
    },
  },

  mounted() {
    if (this.editorData) {
      this.permission = this.editorData.permission == 1 ? "Admin" : "Redakteur";
      this.firstName = this.editorData.first_name;
      this.lastName = this.editorData.last_name;
      this.salutation = this.salutations[this.editorData.salutation - 1];
      this.email = this.editorData.email;
    } else {
      this.permission = "Redakteur";
    }

    document.getElementById("closeButton").focus();
  },
};
</script>
