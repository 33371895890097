<template>
  <div id="event" style="width: 100%">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="6" class="pa-0">
          <v-card flat>
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-calendar</v-icon> Eventübersicht</v-card-title
            >
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0">
          <v-btn
            @click="showNewEvent = true"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0 plusButton"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!eventData || eventData.length == 0">
        <v-col class="px-0">
          <v-card flat>
            <p class="pa-16">Kein Event vorhanden!</p>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          :cols="isMobile ? 12 : 5"
          class="px-0 scrollable hiddenScroll"
          style="padding-top: 0px; padding-bottom: 0px; margin-top: 12px"
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <div
            v-for="(event, index) in eventData"
            :key="event.id + '_card'"
            :id="event.id + '_card'"
            class="mb-3 focusable"
            tabindex="0"
            @click="setActiveEvent(event)"
            @focus="setActiveEvent(event)"
            @keyup.enter="switchFocus()"
            style="border-radius: 4px !important"
          >
            <eventCard :eventData="event" :editView="true" style="border-radius: 4px !important" />

            <div
              v-if="index == eventData.length - 1"
              tabindex="0"
              @focus="rerouteFocus"
              style="opacity: 0; height: 0px"
            >
              Tastatursteuerung Hilfsanker Start
            </div>
          </div>
        </v-col>
        <v-col
          cols="7"
          class="pr-0"
          v-if="!isMobile"
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <eventDetail
            :eventData="activeEvent"
            @edit="showEditView = true"
            @delete="showDeletionWarning = true"
            @requestCheck="sendRequestCheck()"
            @changeStatus="changeStatus"
            @resetFocus="resetFocus"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay style="z-index: 12 !important" :value="showEditView" :dark="false">
      <eventEdit :eventData="activeEvent" @close="hideEditView" />
    </v-overlay>

    <v-overlay style="z-index: 12 !important" :value="showNewEvent" :dark="false">
      <eventEdit
        :eventData="undefined"
        @close="closeNewEventView"
        :preSelectedCompany="companyData[0]"
      />
    </v-overlay>

    <v-overlay
      style="z-index: 12 !important"
      :value="showDeletionWarning"
      v-if="activeEvent"
      :dark="false"
    >
      <deletionWarning
        :item="' das Event'"
        :data="activeEvent.title"
        @cancel="showDeletionWarning = false"
        @confirm="deleteEvent"
      />
    </v-overlay>

    <eventDrawerMobile
      v-if="isMobile"
      :eventData="activeEvent"
      @edit="showEditView = true"
      @delete="showDeletionWarning = true"
      @requestCheck="sendRequestCheck()"
      @changeStatus="changeStatus"
      :open="eventDrawer"
      @close="eventDrawer = 0"
    />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import eventDetail from "../../components/Backoffice/Event/EventDetail.vue";
import eventCard from "../../components/Backoffice/Event/EventCard.vue";

import eventEdit from "../../components/Backoffice/Event/EventEdit.vue";

import deletionWarning from "../../components/Main/DeletionWarning.vue";

import eventDrawerMobile from "../../components/Backoffice/Event/EventDetail_MobileDrawer.vue";

const axios = require("axios");

export default {
  data() {
    return {
      eventData: [],
      activeEvent: undefined,

      showDeletionWarning: false,
      showEditView: false,
      showNewEvent: false,

      eventDrawer: 0,

      companyData: [],
      loading: false,
    };
  },

  components: {
    eventCard,
    eventDetail,
    eventEdit,
    deletionWarning,

    eventDrawerMobile,
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    rerouteFocus(e) {
      document.getElementById("datenschutzLink").focus();
      e.stopPropagation();
      e.preventDefault();
    },
    resetFocus() {
      for (let i = 0; i < this.eventData.length; i++) {
        if (this.eventData[i].id == this.activeEvent.id) {
          if (i < this.eventData.length - 1) {
            document.getElementById(this.eventData[i + 1].id + "_card").focus();
          } else {
            document.getElementById(this.eventData[0].id + "_card").focus();
          }

          return;
        }
      }
    },
    switchFocus() {
      document.getElementById("eventDetail").focus();
    },
    hideEditView(data) {
      this.showEditView = false;
      if (data) {
        this.activeEvent = data;

        for (let i = 0; i < this.eventData.length; i++) {
          if (this.eventData[i].id == data.id) {
            this.eventData[i] = data;
            this.$forceUpdate();
            break;
          }
        }
      }
    },
    closeNewEventView(data) {
      this.showNewEvent = false;
      if (data) {
        this.activeEvent = data;
        this.eventData.push(data);
        data.status = "In Bearbeitung";
        this.requestEvents(data.id);
      }
    },
    setActiveEvent(event) {
      this.activeEvent = event;

      if (this.isMobile) {
        this.eventDrawer++;
      }
    },
    deleteEvent() {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "events/patch/" + this.activeEvent.id, {
          id: this.activeEvent.id,
          deleted: true,
        })
        .then((resp) => {
          for (let i = 0; i < this.eventData.length; i++) {
            if (this.eventData[i].id == resp.data.id) {
              this.eventData.splice(i, 1);
              break;
            }
          }

          if (this.eventData.length > 0) this.activeEvent = this.eventData[0];
          else this.activeEvent = null;

          this.showDeletionWarning = false;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));

          this.loading = false;
        });
    },
    changeStatus(data) {
      axios
        .patch(axios.defaults.baseURL + "events/editor/patch/" + this.activeEvent.id, {
          id: this.activeEvent.id,
          status: data,
        })
        .then((resp) => {
          for (let i = 0; i < this.eventData.length; i++) {
            if (this.eventData[i].id == resp.data.id) {
              if (resp.data.status == 2) this.eventData[i].status = "Akzeptiert";
              else if (resp.data.status == 3) this.eventData[i].status = "Abgelehnt";
            }
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));

          this.loading = false;
        });
    },
    requestEvents(id) {
      this.eventData = [];
      axios
        .get(axios.defaults.baseURL + "events/own")
        .then((resp) => {
          this.eventData = resp.data;

          if (id) {
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].id == id) {
                this.activeEvent = resp.data[i];
              }
            }
          } else {
            this.activeEvent = resp.data[0];
          }
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    requestOwnCompany() {
      axios
        .get(axios.defaults.baseURL + "companies/own")
        .then((resp) => {
          this.companyData = resp.data;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  mounted() {
    this.requestEvents();
    this.requestOwnCompany();
  },
};
</script>
