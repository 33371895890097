<template>
  <div>
    <v-card
      :style="{ width: cardWidth }"
      style="max-height: 90vh"
      class="scrollable px-8 py-12"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="newJobForm">
        <v-container>
          <v-row class="mb-2">
            <v-col class="py-0">
              <h2 class="encodeFont">Neue Berufsbezeichnung</h2>
            </v-col>
          </v-row>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col :cols="12" class="pa-0">
                <v-container>
                  <v-row>
                    <v-col class="pb-0">
                      <v-text-field
                        autofocus
                        outlined
                        :rules="max300"
                        v-model="name"
                        label="Berufsbezeichnung"
                        placeholder="neue Berufsbezeichnung eingeben"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col :cols="12" class="pb-8">
                      <v-select
                        label="Brafo"
                        outlined
                        clearable
                        multiple
                        hide-details
                        :items="brafos"
                        item-text="description"
                        item-value="id"
                        v-model="selectedBrafo"
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip>{{ item.description }}</v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col :cols="12">
                      <v-combobox
                        label="Beruf"
                        clearable
                        hide-details
                        outlined
                        :items="jobNames"
                        item-text="name"
                        item-value="id"
                        v-model="selectedJobName"
                        :rules="validObject"
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip v-if="item.name">{{ item.name }}</v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="12" class="pt-0">
                      <v-checkbox label="Sperren" v-model="blocked"> </v-checkbox>
                    </v-col>
                  </v-row> -->
                </v-container>
              </v-col>
            </v-row>
          </v-container>

          <v-row class="mt-2 py-2">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                block
                large
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                :class="{ 'pa-1': isMobile }"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                block
                large
                elevation="0"
                class="primaryButton py-3"
                :class="{ 'pa-1': isMobile }"
                @click="postNewJob()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "newJobName",
  props: ["brafos"],

  data() {
    return {
      blocked: false,
      jobNames: [],

      selectedBrafo: undefined,
      selectedJobName: undefined,
      valid: undefined,

      name: "",

      validObject: [
        (v) => typeof v == "object" || !v || "Bitte Berufsbezeichnung auswählen.",
      ],
      max300: [
        (v) =>
          !!v || (v.length > 0 && v.length <= 300) || "Bitte Berufsbezeichnung eingeben.",
      ],
    };
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "98vw";
        case "sm":
          return "95vw";
        case "md":
          return "90vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    postNewJob() {
      if (this.$refs.newJobForm.validate()) {
        this.$emit("confirm", {
          name: this.name,
          // mapping: this.selectedJobName?.id,
          brafo: this.selectedBrafo,
          // blocked: this.blocked,
        });
      }
    },
    close() {
      this.$emit("close");
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    fetchJobNames() {
      axios
        .get(axios.defaults.baseURL + "jobnames/all/")
        .then((resp) => {
          this.jobNames = resp.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {

    this.fetchJobNames();

    setTimeout(() => {
      document.getElementById("closeButton").focus();
    this.$refs.newJobForm.resetValidation()
    }, 200);
  },
};
</script>
