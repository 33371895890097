<template>
  <div>
    <div class="footerBottomSpacer">
      <headerComponent />
      <router-view />
    </div>

    <footerComponent />
  </div>
</template>

<script>
import headerComponent from "../../components/Main/Header.vue";
import footerComponent from "../../components/Main/Footer.vue";

export default {
  name: "registerMain",

  components: {
    headerComponent,
    footerComponent,
  },
};
</script>
