<template>
  <div>
    <v-card
      :dark="false"
      style="min-height: 15vh"
      class="scrollable hiddenScroll pa-8"
      :style="{ width: cardWidth, 'max-height': cardMaxHeight }"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-container fluid>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <h2 class="encodeFont">Firma Sperren?</h2>
          </v-col>
        </v-row>
        <br />
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <p>
              Das Unternehmen <b>{{ company.name }}</b> wird gesperrt, seine Stellenanzeigen
              werden nicht mehr angezeigt. Ist die Firma extern, so werden keine neuen
              Stellenanzeigen in die Datenbank übernommen.
            </p>
          </v-col>
        </v-row>
        <br />
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0 pr-2" :cols="isMobile ? 12 : 8">
            <v-btn class="primaryButton py-4" large block elevation="0" @click="close">
              <v-icon>mdi-close</v-icon> Abbrechen
            </v-btn>
          </v-col>
          <v-spacer v-if="!isMobile" />
          <v-col
            class="ma-0 pa-0 pl-2"
            :cols="isMobile ? 12 : 4"
            :class="{ 'mt-3': isMobile }"
          >
            <v-btn
              class="primaryOnWhiteButton lightOutline py-4"
              @click="confirm"
              elevation="0"
              large
              block
            >
              <v-icon>mdi-check</v-icon> Fortfahren
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">Fokus zurück zu "Schließen"</div>
  </div>
</template>

<script>
export default {
  name: "blacklistCompany",

  props: ["company"],

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "50vw";
        case "xl":
          return "50vw";
        default:
          return "65vw";
      }
    },
    cardMaxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vh";
        case "sm":
          return "75vh";
        case "md":
          return "65vh";
        case "lg":
          return "50vh";
        case "xl":
          return "50vh";
        default:
          return "65vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    resetFocus(e){
      e.stopPropagation();
      e.preventDefault();

       document.getElementById("closeButton").focus();
    },
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm", this.company);
    },
  },

  mounted(){
    document.getElementById("closeButton").focus();
  }
};
</script>
