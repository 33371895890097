<template>
  <v-layout
    style="
      height: calc(100vh - 68px);
      position: relative;
      overflow-y: auto;
      width: 100%;
      max-width: inherit;
    "
    class="hiddenScroll"
    :style="{ width: smallDrawer ? '64px' : 'unset' }"
  >
    <v-navigation-drawer
      permanent
      absolute
      class="mt-16"
      style="background-color: var(--v-theme-background)); position:fixed; max-width: inherit"
      :style="{
        width: smallDrawer ? 'unset' : '100%',
      }"
      :mini-variant="smallDrawer"
    >
      <v-container fluid>
        <v-row @click="changeDrawerSize()">
          <v-spacer v-if="smallDrawer" />
          <v-col cols="auto" class="px-0">
            <v-card style="background-color: transparent" class="pa-2" flat>
              <div style="display: flex; align-items: center" class="pb-1 pt-2 mt-3">
                <v-icon
                  :class="{ 'mr-2': !smallDrawer }"
                  style="margin-top: 2px; color: darkgray"
                  >mdi-menu</v-icon
                >

                <b v-if="!smallDrawer">Menü</b>
              </div>
            </v-card>
          </v-col>
          <v-spacer v-if="smallDrawer" />
        </v-row>

        <hr class="my-4" style="opacity: 0.3" />
        <div v-for="(r, index) in routeArray" :key="'route_' + index">
          <v-row
            class="mt-3"
            v-if="!r.props.permission || $store.state.permission == r.props.permission"
          >
            <v-spacer v-if="smallDrawer" />
            <v-col
              v-if="
                r &&
                (r.props.displayInNavigation ||
                  (r.props.displayOnActive && isActive(r))) &&
                !(r.props.hideOnActive && activeRoute.startsWith(r.props.hideOnActive))
              "
              :class="{ 'pb-0': smallDrawer, 'pl-0': !smallDrawer }"
            >
              <v-card
                class="pa-2 pl-1"
                flat
                :style="{
                  'background-color': smallDrawer
                    ? 'transparent'
                    : r.name == activeRoute || activeRoute.startsWith(r.name)
                    ? 'var(--v-accentButLighter-base)'
                    : 'white',
                }"
                @click="gotoRoute(r)"
                @mousedown.middle="openRouteInNewTab(r)"
              >
                <div style="display: flex; align-items: center">
                  <v-tooltip v-if="smallDrawer" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :class="{ 'mr-2': !smallDrawer }"
                        :style="{
                          color: isActive(r) ? 'var(--v-primary-base)' : 'darkgray',
                        }"
                        style="margin-top: 2px"
                        :size="'x-large'"
                        >mdi-{{ r.props.icon }}</v-icon
                      >
                    </template>
                    <span
                      style="
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow-x: hidden;
                        white-space: nowrap;
                      "
                    >
                      {{ r.props.displayName }}</span
                    >
                  </v-tooltip>

                  <v-icon
                    v-else
                    :class="{ 'mr-2': !smallDrawer }"
                    :style="{
                      color: isActive(r) ? 'var(--v-primary-base)' : 'darkgray',
                    }"
                    style="margin-top: 2px"
                    :size="'x-large'"
                    class="ml-2"
                    >mdi-{{ r.props.icon }}</v-icon
                  >

                  <span v-if="isActive(r) && !smallDrawer" style="
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow-x: hidden;
                        white-space: nowrap;
                        display: block
                      ">
                    <b
                      
                      >{{ r.props.displayName }}</b
                    >
                  </span>
                  <span v-else-if="!smallDrawer" style="
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow-x: hidden;
                        white-space: nowrap;
                      ">
                    <p class="mb-0" style="
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow-x: hidden;
                        white-space: nowrap;
                        display: block
                      ">
                      {{ r.props.displayName }}
                    </p>
                  </span>
                </div>
              </v-card>
              <v-expand-transition v-if="showChildren(r)">
                <div
                  v-show="
                    smallDrawer ||
                    r.props.stayActive ||
                    (isActive(r) && r.children && r.children.length > 1)
                  "
                >
                  <v-container>
                    <v-row
                      @click="gotoChild(c)"
                      @mousedown.middle="openChildInNewTab(c)"
                      v-for="(c, childIndex) in r.children"
                      :key="'route_child_' + childIndex"
                      style="cursor: pointer"
                    >
                      <v-col
                        v-if="c.props.displayInNavigation"
                        :class="{ 'pa-0': smallDrawer }"
                      >
                        <v-card
                          class="pa-2"
                          :class="{ 'pr-0': smallDrawer, 'pl-1': smallDrawer }"
                          flat
                          :style="{
                            'background-color': smallDrawer
                              ? 'transparent'
                              : c.name == activeRoute || activeRoute.startsWith(c.name)
                              ? 'var(--v-accentButLighter-base)'
                              : 'white',
                          }"
                        >
                          <div
                            style="display: flex; align-items: center"
                            :style="{
                              'justify-content': smallDrawer ? 'center' : 'flex-start',
                            }"
                          >
                            <v-tooltip v-if="smallDrawer" right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-0"
                                  :class="{ 'pr-1': smallDrawer }"
                                  :size="'x-large'"
                                  :style="{
                                    color:
                                      c.name == activeRoute ||
                                      activeRoute.startsWith(c.name)
                                        ? 'var(--v-primary-base)'
                                        : 'darkgray',
                                  }"
                                  style="margin-top: 2px"
                                  >mdi-{{ c.props.icon }}</v-icon
                                >
                              </template>
                              <span
                                style="
                                  text-overflow: ellipsis;
                                  width: 100%;
                                  overflow-x: hidden;
                                  white-space: nowrap;
                                "
                              >
                                {{ c.props.displayName }}</span
                              >
                            </v-tooltip>

                            <v-icon
                              v-else
                              :class="{ 'mr-2': !smallDrawer }"
                              :size="'x-large'"
                              :style="{
                                color:
                                  c.name == activeRoute || activeRoute.startsWith(c.name)
                                    ? 'var(--v-primary-base)'
                                    : 'darkgray',
                              }"
                              style="margin-top: 2px"
                              >mdi-{{ c.props.icon }}</v-icon
                            >

                            <b
                              v-if="
                                !smallDrawer &&
                                (c.name == activeRoute || activeRoute.startsWith(c.name))
                              "
                              style="
                                text-overflow: ellipsis;
                                width: 100%;
                                overflow-x: hidden;
                                white-space: nowrap;
                              "
                              >{{ c.props.displayName }}</b
                            >
                            <p
                              v-else-if="!smallDrawer"
                              class="mb-0"
                              style="
                                text-overflow: ellipsis;
                                width: 100%;
                                overflow-x: hidden;
                                white-space: nowrap;
                              "
                            >
                              {{ c.props.displayName }}
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-expand-transition>

              <v-divider
                class="mt-1"
                v-if="smallDrawer && index != routeArray.length - 1"
              />
            </v-col>
            <v-spacer v-if="smallDrawer" />
          </v-row>
        </div>
      </v-container>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import { routes } from "../../router/index.js";

export default {
  name: "navigationDrawer",

  props: ["baseRoute"],

  data() {
    return {
      drawer: true,
      smallDrawer: true,

      routeArray: undefined,
    };
  },

  computed: {
    isAdmin() {
      return this.$store.state.permission == "admin";
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        case "xxl":
          return false;
        default:
          return false;
      }
    },

    activeRoute() {
      return this.$route.name;
    },
    subMenuObject() {
      if (
        this.$route.matched[this.$route.matched.length - 2] &&
        this.$route.matched[this.$route.matched.length - 2].props.default.switchMenu
      ) {
        return this.$route.matched[this.$route.matched.length - 2];
      }

      return undefined;
    },
    getRouteTypeParam() {
      return this.$route.params.type;
    },
  },

  watch: {
    "$vuetify.breakpoint.name"() {
      if (this.isMobile) {
        this.smallDrawer = true;
      }
    },
    smallDrawer() {
      this.$store.dispatch("switchDrawerSize", this.smallDrawer);
    },
  },

  methods: {
    isActive(route) {
      return route.name == this.activeRoute || this.activeRoute.startsWith(route.name);
    },
    showChildren(routeObject) {
      if (routeObject.children) {
        for (let i = 0; i < routeObject.children.length; i++) {
          if (
            routeObject.children[i].props &&
            routeObject.children[i].props.displayInNavigation
          ) {
            return true;
          }
        }
      }
      return false;
    },
    calculateRoute(routeObject) {
      if (routeObject.props.parentMenu) {
        return "/" + this.baseRoute + "/" + routeObject.props.parentMenu;
      } else if (routeObject.props.rerouteToChild && routeObject.children) {
        return (
          "/" +
          this.baseRoute +
          "/" +
          routeObject.path +
          "/" +
          routeObject.children[0].path
        );
      } else {
        return "/" + this.baseRoute + "/" + routeObject.props.path;
      }
    },
    gotoRoute(routeObject) {
      this.$router.push(this.calculateRoute(routeObject));
    },
    openRouteInNewTab(routeObject) {
      window.open(this.calculateRoute(routeObject), "_blank");
    },
    getChildPath(routeObject) {
      let path = this.$router.resolve({
        name: routeObject.name,
      });

      return path.route.fullPath;
    },
    gotoChild(routeObject) {
      this.$router.push(this.getChildPath(routeObject));
    },
    openChildInNewTab(routeObject) {
      window.open(this.getChildPath(routeObject), "_blank");
    },
    gotoParent() {
      this.$router.push(
        "/" + this.baseRoute + "/" + this.subMenuObject.props.default.parentMenu
      );
    },
    changeDrawerSize() {
      if (this.isMobile) {
        return;
      }

      this.smallDrawer = !this.smallDrawer;
    },
  },

  mounted() {
    if (this.isMobile) {
      this.smallDrawer = true;
      this.$store.dispatch("switchDrawerSize", this.smallDrawer);
    } else {
      if (this.$store.state.smallDrawer) {
        let temp = parseInt(this.$store.state.smallDrawer);
        this.smallDrawer = temp ? true : false;
      } else {
        this.smallDrawer = false;
      }
    }

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].name == this.baseRoute) {
        this.routeArray = routes[i].children;
        break;
      }
    }
  },
};
</script>
