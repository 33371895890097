<template>
  <div>
    <v-navigation-drawer
      app
      class="pa-0 pb-0 scrollable disableOverscroll"
      right
      temporary
      style="overflow-x: hidden; width: 100vw; background-color: white"
      v-model="drawer"
      :style="{ height: '100vh' }"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <companyDetail
        :editorMode="editorMode"
        :company="company"
        @edit="showEditView()"
        @blacklist="showBlacklistView()"
        @deleteContact="showDeleteContact"
        @editContact="showEditContact"
        class="mt-12"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import companyDetail from "./CompanyDetail.vue";

export default {
  name: "companyDetailMobileDrawer",

  props: ["company", "open", "editorMode"],

  data() {
    return {
      drawer: false,
    };
  },

  watch: {
    open() {
      if (this.open > 0) {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    },
  },

  components: {
    companyDetail,
  },

  methods: {
    close() {
      this.$emit("close");
    },
    showEditView() {
      this.$emit("edit");
    },
    showBlacklistView() {
      this.$emit("blacklist");
    },
    showEditContact(contact){
      this.$emit("editContact", contact)
    },
    showDeleteContact(contact){
      this.$emit("deleteContact", contact)
    },
  },
};
</script>
