<template>
  <div>
    <div class="footerBottomSpacer">
      <HeaderComponentVue />

      <v-container fluid class="py-0">
        <v-row>
          <v-col :cols="navCols" class="px-0">
            <NavigationDrawerVue :baseRoute="'redakteure'" />
          </v-col>

          <v-col
            :cols="mainCols"
            :class="{ 'px-9': !isMobile, 'px-4': isMobile }"
            style="display: flex"
            :style="{ 'min-height': 'calc(100vh - ' + headerHeight + 'px)' }"
          >
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <FooterComponentVue />
  </div>
</template>

<script>
import HeaderComponentVue from "../../components/Main/Header.vue";
import FooterComponentVue from "../../components/Main/Footer.vue";
import NavigationDrawerVue from "../../components/Main/NavigationDrawer.vue";

export default {
  name: "editorMain",

  data() {
    return {
      smallDrawer: false,
    };
  },

  components: {
    HeaderComponentVue,
    FooterComponentVue,
    NavigationDrawerVue,
  },

  watch: {
    "$store.state.smallDrawer"() {
      this.smallDrawer = this.$store.state.smallDrawer;
    },
  },

  computed: {
    headerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 225;
        case "sm":
          return 225;
        case "md":
          return 175;
        case "lg":
          return 175;
        case "xl":
          return 175;
        case "xxl":
          return 175;
        default:
          return undefined;
      }
    },
    mainCols() {
      if (this.smallDrawer) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 9;
          case "sm":
            return 10;
          case "md":
            return 11;
          case "lg":
            return 11;
          case "xl":
            return 9;
          case "xxl":
            return 9;
          default:
            return undefined;
        }
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 10;
        case "sm":
          return 10;
        case "md":
          return 9;
        case "lg":
          return 10;
        case "xl":
          return 9;
        case "xxl":
          return 9;
        default:
          return undefined;
      }
    },
    navCols() {
      if (this.smallDrawer || this.isMobile) {
        return "auto";
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
          return 2;
        case "md":
          return 2;
        case "lg":
          return 2;
        case "xl":
          return 1;
        case "xxl":
          return 1;
        default:
          return 2;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        case "xxl":
          return false;
        default:
          return false;
      }
    },
  },

  mounted() {
    if (this.$store.state.smallDrawer) {
      let temp = parseInt(this.$store.state.smallDrawer);
      this.smallDrawer = temp ? true : false;
    } else {
      this.smallDrawer = false;
    }
  },
};
</script>
