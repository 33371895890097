<template>
  <div>
    <v-container fill-height style="align-content: center" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card
            class="pa-8 mt-12"
            style="
              height: 65vh;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              background-color:#F1F1F1;
            "
          >
            <v-img
              :width="fontSize * 5 + 'em'"
              :height="fontSize * 5 + 'em'"
              class="mainPageImageOffset2"
              :src="require('../../assets/images/logo.svg')"
            ></v-img>
            <v-btn id="closeButton" @click="close()" class="headerButton" text style="position: absolute; top: 1%; right: 1%; z-index: 9;">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card flat style="min-height: 55vh; height: fit-content; background-color:#F1F1F1;" class="scrollable">
              <brafoWorlds @search="search" class="py-4 mt-12 pr-2" />
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import brafoWorlds from "./BrafoWorlds.vue";
export default {
  name: "nonMobileBrafo",

  computed: {
    fontSize() {
      if(window.innerHeight < 750){
        return 1.5
      }
      
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1.5;
        case "sm":
          return 2;
        case "md":
          return 2.5;
        case "lg":
          return 2.5;
        case "xl":
          return 2.5;
        default:
          return 3;
      }
    },
  },

  components: {
    brafoWorlds,
  },

  methods: {
    close() {
      this.$emit("close");
    },
    search() {
      this.$emit("search");
    },
  },

  mounted(){
    document.getElementById("closeButton").focus();
  }
};
</script>
