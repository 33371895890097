<template>
  <div>
    <v-card style="width: 1000px" light>
      <v-container fluid>
        <v-row align="end" class="px-5">
          <v-col cols="10">
            <span style="font-size: 24px">Brafo zuordnen</span></v-col
          >
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              style="position: absolute; right: 0%; top: 0%"
              class="mt-2 headerButton"
              text
              id="closeButton"
              @click="hideOverlay"
            >
              <v-icon size="x-large" color="black">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-8">
            <v-select
              label="Brafo"
              outlined
              clearable
              multiple
              hide-details
              :items="brafos"
              item-text="description"
              item-value="id"
              v-model="selected"
            >
              <template v-slot:selection="{ item }">
                <v-chip>{{ item.description }}</v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-8">
          <v-col
            :cols="isMobile ? 12 : 4"
            :class="{ 'pr-2': !isMobile }"
            style="display: flex; justify-content: center"
            class="pl-5"
          >
            <v-btn
              @click="hideOverlay"
              depressed
              large
              block
              class="px-12 py-4 primaryButton"
            >
              Abbrechen
            </v-btn>
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 8"
            style="display: flex; justify-content: center"
            :class="{ 'mt-3': isMobile, 'pl-2': !isMobile }"
            class="pr-5"
          >
            <v-btn
              @click="confirmMapping"
              depressed
              large
              block
              class="px-12 py-4 primaryOnWhiteButton lightOutline"
            >
              <v-icon class="mr-4">mdi-check</v-icon> Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "JobBrafoOverlay",
  props: ["brafos", "jobName"],
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    hideOverlay() {
      this.$emit("close");
    },
    confirmMapping() {
      this.$emit("confirm", this.selected);
    },
    setBrafos() {
      for (let i = 0; i < this.jobName.Brafo.length; i++) {
        this.selected.push(this.jobName.Brafo[i]);
      }
    },
  },
  mounted() {
    this.setBrafos();
    document.getElementById("closeButton").focus();
  },
};
</script>
