<template>
  <div>
    <div style="min-height: 100vh">
      <headerComponent />
      <v-container>
        <v-row>
          <v-spacer />
          <v-col :cols="getCols">
            <h1 class="encodeFont">Datenschutz</h1>

            <h2 class="encodeFont">1. Verantwortlicher</h2>
            <p style="font-size: 18px">
              Verantwortlicher im datenschutzrechtlichen Sinne für diese Webseite ist die
              Industrie- und Handelskammer Halle-Dessau, vertreten durch den Präsidenten
              und den Hauptgeschäftsführer, Franckestraße 5, 06110 Halle (Saale), Tel.
              0345 21260, E-Mail: info@halle.ihk.de.
              <br />
              Die Kontaktdaten unseres Datenschutzbeauftragten lauten: Prof. Dr. Andre
              Döring, Robin Data GmbH, Fritz-Haber-Straße 9, 06217 Merseburg, Tel.: 03461
              47989-61, E-Mail: datenschutz@robin-data.io.
            </p>
            <h2 class="encodeFont">2. Besuch unserer Website</h2>
            <p style="font-size: 18px">
              Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum
              Einsatz kommenden Browser automatisch Informationen an den Server unserer
              Website gesendet. Diese Informationen werden temporär in einem sog. Logfile
              gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und
              bis zur automatisierten Löschung gespeichert: 
              <br>
              <ul>
                <li>IP-Adresse des anfragenden Rechners</li>
                <li>Datum und Uhrzeit des Zugriffs</li>
                <li>Name und URL der abgerufenen Datei</li>
                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
              </ul>
              Die Verarbeitung dieser Daten
              erfolgt gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO. Das berechtigte Interesse
              folgt aus der Sicherstellung der technischen Funktionalität unserer Website.
              Die Log-Files werden nach 30 Tagen gelöscht.
            </p>
            <h2 class="encodeFont">3. Dritt-Seiten</h2>
            <p style="font-size: 18px">
              Soweit wir auf der Seite Verweise auf andere Webseiten (Links) bieten, 
              gilt unsere Datenschutzrichtlinie nicht für diese Seiten. Bitte lesen Sie 
              jeweils die dortigen Datenschutzhinweise. Wir haften mangels Einflussmöglichkeit 
              nicht für Inhalte und Wirkungsweisen externer Webseiten. Wir erhalten von den 
              fremden Webseiten auch keinerlei Daten über Sie, wenn Sie den Links folgen oder 
              auf den verlinkten Seiten Daten eingeben.
            </p>
            <h2 class="encodeFont">4. Cookies</h2>
            <p style="font-size: 18px">
             Auf dieser Internetseite kommen Cookies zum Einsatz. Cookies richten auf Ihrem 
             Rechner keinen Schaden an und enthalten keine Viren. Es handelt sich vielmehr 
             um kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser 
             speichert.
             <br>
             Sie dienen einerseits der technischen Funktionsfähigkeit unserer Website.
             Andererseits ermöglichen sie uns die Verbesserung der Nutzerfreundlichkeit
              sowie der Effektivität und Sicherheit unserer Website sowie unseres Angebotes. 
             <br>
             Soweit die Cookies dem technischen Betrieb der Website dienen und für 
             den technischen Betrieb essenziell sind, handelt es sich hierbei um ein 
             berechtigtes Interesse im Sinne von Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies, 
             die für den Betrieb der Website nicht essenziell sind, werden nur auf Basis 
             Ihrer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO gesetzt.
              <br>
              Nähere Informationen zu den eingesetzten Cookies, insbesondere zu den 
              Löschfristen, entnehmen Sie bitte dem Cookie-Consent-Manager, den Sie <a @click="openConsentManager"><u style="color:var(--v-primary-base)">hier</u></a> erreichen.
            </p>
            <h2 class="encodeFont">5. Kontaktaufnahme</h2>
            <p style="font-size: 18px">
              Wenn Sie uns über die auf unserer Website genannten Kontaktdaten eine Nachricht 
              zukommen lassen, werden Ihre Kontaktdaten sowie alle weiteren sich aus der 
              Nachricht ergebenden personenbezogenen Daten zwecks Bearbeitung der Anfrage
               bei uns verarbeitet. 
              <br>
              Zu denen von uns verarbeiteten personenbezogenen Daten können gehören: 
              <ul>
                <li>Anrede</li>
                <li>Vor- und Nachname</li>
                <li>Anschrift</li>
                <li>Telefon- oder Faxnummer</li>
                <li>E-Mail-Adresse</li>
              </ul>

              Die Verarbeitung dieser Daten erfolgt auf Grundlage Ihrer (konkludenten) 
              Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO. Sofern die Kommunikation der 
              Anbahnung eines Geschäftes dient, erfolgt die Verarbeitung zudem auf Basis 
              von Art. 6 Abs. 1 S. 1 lit. b DSGVO
              <br>
              Die Daten werden nach Bearbeitung der Angelegenheit unverzüglich gelöscht, 
              sofern die Kommunikation nicht zur Abwehr bzw. Geltendmachung von Forderungen 
              notwendig ist oder diese aufgrund steuerrechtlicher Vorgaben aufbewahrt werden 
              müssen. In diesem Fall erfolgt die Löschung nach drei Jahren, beginnend mit dem 
              Jahr, das auf das Jahr, in dem die Kommunikation stattfand, oder nach 10 Jahren.

            </p>
            <h2 class="encodeFont">6. Mapbox</h2>
            <p style="font-size: 18px">
              Zum Zwecke der Verbesserung der Nutzererfahrung nutzen wir zur 
              Darstellung von Karten Mapbox der Fa. Mapbox, Inc., 740 15th Street NW, 
              Washington DC 20005, USA. 
              <br>
              In diesem Zusammenhang werden folgende personenbezogene Daten verarbeitet, 
              insbesondere weitergegeben:
              <ul>
                <li>IP-Adresse, Geräte-Modell, Browserinformationen, Betriebssystem</li>
                <li>zu suchende Adressdaten</li>
                <li>Standort, Geschwindigkeit, Richtung, Zeitpunkt der Abfrage</li>
              </ul>

              Wir weisen Sie darauf hin, dass bei einem unsicheren Drittstaat (USA) 
              nicht sichergestellt ist, dass bei der Verarbeitung Ihrer personenbezogenen 
              Daten die datenschutzrechtlichen Vorgaben der Datenschutzgrundverordnung 
              eingehalten werden. Insbesondere kann aufgrund gesetzlicher Vorgaben in dem 
              jeweiligen Drittland der Betreiber gezwungen sein, Ihre personenbezogenen Daten an 
              Behörden und andere staatliche Einrichtungen herauszugeben.
              <br>
              Rechtsgrundlage für die Verarbeitung ist Ihre Zustimmung gem. Art. 6 Abs. 1 lit. a) 
              DSGVO.
              <br>
              Eine Speicherung von personenbezogenen Daten durch uns findet nicht statt.
              <br>
              Weitere Informationen zum Datenschutz bei Mapbox finden Sie unter 
              folgendem Link: https://www.mapbox.com/legal/dpa.

            </p>
            <h2 class="encodeFont">7. Betroffenenrechte</h2>
            <p style="font-size: 18px">
              Sie haben das Recht:
              <ul>
                <li>gemäß Art. 7 Abs. 3 DSGVO eine einmal erteilte Einwilligung jederzeit 
                  gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, 
                  die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;
                  </li>
                  <li>
                    gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
                  </li>
                  <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                  </li>
                   <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;

                  </li>
                   <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;

                  </li>
                   <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;

                  </li>
                   <li>gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit die Verarbeitung auf Basis unseres berechtigten Interesses erfolgt sowie für den Widerspruch Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben und

                  </li>
                   <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Firmensitzes wenden.

                  </li>
              </ul>
            </p>

          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <footerComponent />
  </div>
</template>

<script>
import headerComponent from "../../components/Main/Header.vue";
import footerComponent from "../../components/Main/Footer.vue";

export default {
  name: "imprint",

  components: {
    headerComponent,
    footerComponent,
  },

  computed: {
    getCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 10;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
    openConsentManager(){
      window.dispatchEvent(
        new CustomEvent("openConsentManager")
      );
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
