<template>
  <div style="height: 100vh" id="login">
    <HeaderComponentVue />
    <v-container fill-height style="margin-top: -64px !important">
      <v-row justify="center">
        <v-spacer />
        <v-col :cols="colWidth">
          <v-card flat class="pa-6">
            <v-container fluid class="ma-auto">
              <v-row justify="center" no-gutters class="mb-6">
                <v-col :cols="6" @click="gotoMain()">
                  <v-img :src="require('../assets/images/logo.svg')"></v-img>
                </v-col>
              </v-row>

              <v-form ref="form" lazy-validation>
                <v-row justify="center" no-gutters>
                  <v-col :cols="12">
                    <v-text-field
                      id="email"
                      :rules="emailRules"
                      label="E-Mail"
                      v-model="email"
                      required
                      outlined
                      background-color="white"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      :rules="passwordRules"
                      :type="showEyeOpen ? 'password' : 'regular'"
                      label="Passwort"
                      v-model="password"
                      required
                      outlined
                      :append-icon="showEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="toggleShowPassword"
                      background-color="white"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="throttled" class="pa-0 mb-3">
                  <v-col cols="12" class="py-0">
                    <i style="color:red">Zu viele Loginversuche. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</i>
                  </v-col>
                </v-row>

                <v-row justify="center" no-gutters>
                  <v-col :cols="12">
                    <v-btn
                      @click="submit"
                      block
                      depressed
                      large
                      color="primary"
                      elevation="2"
                      >Anmelden</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>

              <v-row md="auto" justify="center" no-gutters class="mt-6">
                <v-col
                  :cols="isMobile ? 12 : 6"
                  :style="{ 'text-align': isMobile ? 'center' : 'left' }"
                >
                  <a :href="url">Passwort vergessen?</a>
                </v-col>
                <v-col
                  :cols="isMobile ? 12 : 6"
                  :style="{ 'text-align': isMobile ? 'center' : 'right' }"
                  :class="{ 'mt-3': isMobile }"
                >
                  <a @click="gotoRegister()"> Jetzt Unternehmen registrieren! </a>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import HeaderComponentVue from "../components/Main/Header.vue";

export default {
  name: "login",

  metaInfo: {
    title: "Login Bereich",
    titleTemplate: "%s - Login",
    htmlAttrs: {
      lang: "de",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Login Bereich für Unternehmen und Mitarbeiter IHK Halle-Dessau",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  data() {
    return {
      isValid: true,
      email: "",
      emailRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "Keine E-mail",
      ],
      password: "",
      passwordRules: [(v) => (v && this.isValid) || "E-Mail oder Passwort falsch!"],

      url: axios.defaults.url + "/accounts/password_reset/",

      showEyeOpen: true,

      throttled: false,
    };
  },

  components: {
    HeaderComponentVue
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 8;
        case "lg":
          return 6;
        case "xl":
          return 5;
        default:
          return 4;
      }
    },
  },

  methods: {
    gotoRegister() {
      this.$router.push("/registrieren/anfrage");
    },
    gotoMain() {
      this.$router.push("/");
    },
    submitByEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.submit();
      }
    },
    submit() {
      this.email = this.email.toLowerCase();
      const formData = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("loginUser", formData).catch((err) => {
        if (err.response.status == 401 || err.response.status == 403) {
          this.isValid = false;

          this.$refs.form.validate();
        }
        else if(err.response.status == 429){
          this.throttled = true;
          this.$refs.form.validate();
        }
      });
    },
    toggleShowPassword() {
      this.showEyeOpen = !this.showEyeOpen;
    },
  },

  mounted() {
    document.getElementById("app").addEventListener("keyup", this.submitByEnter);
  },

  beforeDestroy() {
    document.getElementById("app").removeEventListener("keyup", this.submitByEnter);
  },
};
</script>
