<template>
  <div style=" height: 100%">
    <div
      style="display: flex; flex-direction: column; justify-content: space-between; height: 100%"
    >
      <v-card
        id="eventDetail"
        tabindex="0"
        flat
        class="focusable"
        :class="{ scrollable: !isMobile, disableOverscroll: isMobile }"
        :style="{ 'max-height': height, height: height }"
        style="cursor: unset"
        :ripple="false"
      >
        <div
          v-if="eventData"
          style="display: flex; flex-direction: column; justify-content: flex-end"
        >
          <v-container class="pa-6">
            <v-row style="display: flex" v-if="!resultView">
              <v-spacer />
              <v-col cols="auto" class="editCardButtons">
                <v-tooltip bottom v-if="eventData && eventData.expired">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                        color: var(--v-primary-base);
                      "
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                      tabindex="0"
                      >mdi-calendar-remove</v-icon
                    >
                  </template>
                  <span>Das Event ist abgelaufen</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                  v-else-if="eventData &&eventData.status == 'Akzeptiert' && !eventData.expired"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      tabindex="0"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3 focusableIcon"
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                        color: var(--v-primary-base);
                      "
                      >mdi-check</v-icon
                    >
                  </template>
                  <span>Das Event wurde veröffentlicht</span>
                </v-tooltip>

                <v-tooltip
                  bottom
                  v-else-if="eventData && eventData.status == 'Abgelehnt' && !eventData.expired"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                        color: var(--v-primary-base);
                      "
                      >mdi-close</v-icon
                    >
                  </template>
                  <span>Das Event wurde abgelehnt</span>
                </v-tooltip>

                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                      style="
                        text-align: center;
                        margin: 0 auto;
                        padding-right: 0 !important;
                      "
                      >mdi-clock-outline</v-icon
                    >
                  </template>
                  <span>Das Event benötigt Bestätigung eines Redakteurs</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="eventData && eventData.expired"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class=""
                      @click="editEvent()"
                      ><v-icon
                        class="pr-2"
                        style="
                          text-align: center;
                          color: var(--v-primary-base) !important;
                          margin: 0 auto;
                          padding-right: 0 !important;
                        "
                        >mdi-pencil</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="deleteEvent()"
                      ><v-icon
                        class="pr-2"
                        style="
                          color: var(--v-primary-base) !important;
                          text-align: center;
                          margin: 0 auto;
                          padding-right: 0 !important;
                        "
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>Löschen</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col :cols="isMobile ? 12 : 4">
                <v-img
                  class="imageContain"
                  v-if="eventData.logo"
                  :src="eventData.logo"
                  :alt="'Eventlogo für ' + eventData.title"
                  style="max-height: 200px"
                ></v-img>
                <v-img
                  v-else
                  class="imageCover"
                  src="../../../assets/images/noImg.svg"
                  :alt="'Platzhalterlogo für ' + eventData.title"
                  style="max-height: 200px"
                ></v-img>
              </v-col>
              <v-col
                :cols="isMobile ? 12 : 8"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <h2 class="encodeFont">{{ eventData.title }}</h2>
                <span v-if="!isMobile">
                  <v-chip class="mr-1 mb-1"
                    >Von: {{ eventData.start_date | moment("DD.MM.YY") }}</v-chip
                  >
                  <v-chip class="mr-1 mb-1"
                    >{{ getReadableTime(eventData.start_time) }} Uhr</v-chip
                  >
                  <v-chip class="mr-1 mb-1"
                    >Bis: {{ eventData.end_date | moment("DD.MM.YY") }}</v-chip
                  >
                  <v-chip class="mr-1 mb-1"
                    >{{ getReadableTime(eventData.end_time) }} Uhr</v-chip
                  >
                </span>
              </v-col>
            </v-row>
            <v-row v-if="isMobile">
              <v-col cols="12">
                <span>
                  <v-chip class="mr-1 mb-1"
                    >Von: {{ eventData.start_date | moment("DD.MM.YY") }}</v-chip
                  >
                  <v-chip class="mr-1 mb-1"
                    >{{ getReadableTime(eventData.start_date) }} Uhr</v-chip
                  >
                </span>
              </v-col>
            </v-row>
            <v-row v-if="isMobile" class="mb-3">
              <v-col cols="12">
                <span>
                  <v-chip class="mr-1 mb-1"
                    >Bis: {{ eventData.end_date | moment("DD.MM.YY") }}</v-chip
                  >
                  <v-chip class="mr-1 mb-1"
                    >{{ getReadableTime(eventData.end_date) }} Uhr</v-chip
                  >
                </span>
              </v-col>
            </v-row>
            <hr />
            <v-row class="mt-2">
              <v-col>
                <h2 class="encodeFont">Beschreibung</h2>
                <br />
                <p v-html="eventData.description" class="encodeFont"></p>

                <br />
                <br />
                <br />

                <div
                  v-if="
                    eventData.weblink &&
                    eventData.weblink != 'null' &&
                    eventData.weblink != 'undefined'
                  "
                >
                  <h2 class="encodeFont">Webseite</h2>
                  <br />
                  <a
                    @click="gotoExternal(eventData.weblink)"
                    @keyup.enter="gotoExternal(eventData.weblink)"
                    target="_blank"
                    tabindex="0"
                    >{{ eventData.weblink }}</a
                  >
                  <br />
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col>
                <h2 class="encodeFont">Adresse</h2>
                <br />
                <span v-if="eventData.Address">
                  {{ eventData.Address.street }} <br />
                  {{
                    eventData.Address.City.postcode + " " + eventData.Address.City.city
                  }}
                  <br />
                  {{ eventData.Address.additional }}
                  <br />
                </span>
                <span v-else> Keine Adresse vorhanden. </span>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>

      <v-card flat class="rounded-0" id="editorCard">
        <v-container v-if="!resultView" class="mt-2">
          <v-row v-if="isEditor">
            <v-col class="ma-0 pa-0" cols="12" style="text-align: center">
              <span v-if="eventData && eventData.expired" style="color: var(--v-primary-base)"
                >Das Event ist <b>abgelaufen</b>.</span
              >
              <span
                v-else-if="eventData && eventData.status == 'Akzeptiert'"
                style="color: var(--v-primary-base)"
                >Das Event wurde <b>veröffentlicht</b>.</span
              >
              <span
                v-else-if="eventData && eventData.status == 'Abgelehnt'"
                style="color: var(--v-primary-base)"
                >Das Event wurde <b>abgelehnt</b>.</span
              >
            </v-col>
          </v-row>

          <v-row v-if="isEditor">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                style="min-height: 32px"
                block
                class="primaryOnWhiteButton lightOutline py-3"
                elevation="0"
                @click="showDeclineEvent++"
                :disabled="eventData && (eventData.expired || eventData.status != 'In Bearbeitung')"
                ><v-icon class="pr-2">mdi-close</v-icon>Ablehnen</v-btn
              >
            </v-col>
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                style="min-height: 32px"
                block
                class="primaryButton px-0 py-3"
                elevation="0"
                @click="showAcceptEvent++"
                :disabled="eventData && (eventData.expired || eventData.status != 'In Bearbeitung')"
                ><v-icon class="pr-2">mdi-check</v-icon>Veröffentlichen</v-btn
              >
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>

        <a
          tabindex="0"
          style="opacity: 0; position: absolute; pointer-events: none"
          @focus="backToSelectionList"
          >zurück zur Liste</a
        >
      </v-card>
    </div>

    <v-overlay :value="showAcceptEvent" :dark="false">
      <acceptEvent
        @confirm="changeStatus(2)"
        @close="showAcceptEvent = 0"
        :event="eventData"
      />
    </v-overlay>

    <v-overlay :value="showDeclineEvent" :dark="false">
      <declineEvent
        @confirm="changeStatus(3)"
        @close="showDeclineEvent = 0"
        :event="eventData"
      />
    </v-overlay>
  </div>
</template>

<script>
import { permissions } from "../../../util/permissions.js";

import acceptEvent from "../Editor/AcceptEvent.vue";
import declineEvent from "../Editor/DeclineEvent.vue";

export default {
  name: "backofficeEventDetail",

  props: ["eventData", "resultView", "height"],

  data() {
    return {
      showAcceptEvent: 0,
      showDeclineEvent: 0,
    };
  },

  components: {
    acceptEvent,
    declineEvent,
  },

  computed: {
    getMaxCardHeight() {
      if (this.isEditor) {
        if (document.getElementById("editorCard")) {
          return (
            "calc(100% - " +
            parseInt(document.getElementById("editorCard").style.height) * 1.5 +
            "px"
          );
        } else {
          return "100%";
        }
      } else {
        return "100%";
      }
    },
    isEditor() {
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        return true;
      }
      return false;
    },
    isMobile() {
      // if (window.innerHeight < 750) {
      //   return true;
      // }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    backToSelectionList(e) {
      this.$emit("resetFocus");
      e.preventDefault();
      e.stopPropagation();
    },
    getReadableTime(value) {
      if (!value) return "--:--";

      try {
        let temp = value.split("T")[1].split(":");
        return temp[0] + ":" + temp[1];
      } catch (e) {
        let temp = value.split(":");
        return temp[0] + ":" + temp[1];
      }
    },
    gotoExternal(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
    deleteEvent() {
      this.$emit("delete");
    },
    editEvent() {
      this.$emit("edit");
    },
    requestCheckEvent() {
      this.$emit("requestCheck");
    },
    changeStatus(data) {
      this.$emit("changeStatus", data);

      this.showAcceptEvent = 0;
      this.showDeclineEvent = 0;
    },
  },
};
</script>
