var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:({ opacity: _vm.eventData && _vm.eventData.expired ? 0.5 : 1 }),attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"4"}},[(_vm.eventData.logo)?_c('v-img',{attrs:{"src":_vm.eventData.logo,"alt":'Eventlogo für ' + _vm.eventData.title}}):_c('v-img',{staticClass:"imageCover",attrs:{"src":require("../../../assets/images/noImg.svg"),"alt":'Platzhalterlogo für ' + _vm.eventData.title}})],1),_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},attrs:{"cols":7}},[_c('div',[_c('h2',{staticClass:"encodeFont mb-3"},[_vm._v(" "+_vm._s(_vm.eventData.title)+" "),(_vm.editView)?_c('div',{staticClass:"mt-2 mr-2",staticStyle:{"position":"absolute","top":"0","right":"0"}},[(_vm.eventData && _vm.eventData.expired)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",staticStyle:{"text-align":"center","margin":"0 auto","padding-right":"0 !important","color":"var(--v-primary-base)"},attrs:{"tabindex":"0"}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-remove")])]}}],null,false,3801485068)},[_c('span',[_vm._v("Das Event ist abgelaufen")])]):(_vm.eventData.status == 'Akzeptiert')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-2",staticStyle:{"text-align":"center","margin":"0 auto","padding-right":"0 !important","color":"var(--v-primary-base)"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,false,861229628)},[_c('span',[_vm._v("Veröffentlicht")])]):(_vm.eventData.status == 'In Bearbeitung')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",staticStyle:{"color":"var(--v-primary-base)","text-align":"center","margin":"0 auto","padding-right":"0 !important"}},[_vm._v("mdi-clock-outline")])],1)]}}],null,false,3129478434)},[_c('span',[_vm._v("Wird geprüft")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",staticStyle:{"color":"var(--v-primary-base)","text-align":"center","margin":"0 auto","padding-right":"0 !important"}},[_vm._v("mdi-close")])],1)]}}],null,false,3530964209)},[_c('span',[_vm._v("Abgelehnt")])])],1):_vm._e()])]),(!_vm.isMobile)?_c('div',[_c('v-chip',{staticClass:"mr-2 mb-2",staticStyle:{"color":"white","background-color":"var(--v-secondary-base) !important"}},[_vm._v("Event")]),_c('v-chip',{staticClass:"mr-2 mb-2"},[_vm._v("Von: "+_vm._s(_vm._f("moment")(_vm.eventData.start_date,"DD.MM.YY")))]),_c('v-chip',{staticClass:"mb-2"},[_vm._v("Bis: "+_vm._s(_vm._f("moment")(_vm.eventData.end_date,"DD.MM.YY")))])],1):_vm._e()])],1),(_vm.isMobile)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-chip',{staticClass:"mr-3",staticStyle:{"color":"white","background-color":"var(--v-secondary-base) !important"}},[_vm._v("Event")]),_c('v-chip',{staticClass:"mr-3"},[_vm._v("Von: "+_vm._s(_vm._f("moment")(_vm.eventData.start_date,"DD.MM.YY")))]),_c('v-chip',[_vm._v("Bis: "+_vm._s(_vm._f("moment")(_vm.eventData.end_date,"DD.MM.YY")))])],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }