<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="py-1 chartHeader" style="flex-grow: 0">
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>
    <JSCharting
      v-if="jobTypes"
      ref="jtChart"
      :options="jobTypeOptions"
      style="flex-grow: 1"
      @rendered="fillChart"
    />
    <div
      v-else
      style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
    >
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import JSCharting from "jscharting-vue";

export default {
  name: "JobTypeChart",
  props: ["jobTypes", "label"],

  data() {
    return {
      jobTypeOptions: {
        type: "pie",
        debug: false,
        legend: {
          template: "%icon %xValue",
          position: "inside right bottom",
          // visible: false,
        },
        // defaultSeries_angle: 22, // Changes default rotation of diagram, rotates 'A' to the right
        // defaultSeries_angle: { // Makes Pie chart only go from start degree to end degree
        //     start: 0,
        //     end: 270,
        // },
        defaultPoint: {
          label: {
            text: "%yValue",
            style: {
              fontSize: "16px",
            },
          },
          outline: {
            width: 0,
          },
        },
        series: [
          {
            name: "Job Types",
            defaultPoint_tooltip: `%xValue<br>
              %yValue Anfragen<br>
              (%PercentOfTotal%)`,
          },
        ],
      },
      greyColors: [
        "rgb(110,110,110)",
        "rgb(115,115,115)",
        "rgb(130,130,130)",
        "rgb(125,125,125)",
        "rgb(150,150,150)",
        "rgb(135,135,135)",
        "rgb(170,170,170)",
        "rgb(145,145,145)",
        "rgb(190,190,190)",
        "rgb(155,155,155)",
        "rgb(160,160,160)",
        "rgb(165,165,165)",
        "rgb(170,170,170)",
      ],
      points: undefined,
    };
  },
  components: {
    JSCharting,
  },
  methods: {
    jobTypeNumbers() {
      let points = [];
      let i = 0;
      for (const [key, value] of Object.entries(this.jobTypes)) {
        points.push({
          x:
            key.split("__").length > 1
              ? `Praktikum +<br>Ausbildung`
              : key.charAt(0).toLocaleUpperCase() + key.slice(1),
          y: value,
          focusGlow: {
            color: this.$vuetify.theme.defaults.light.primary,
            width: 2,
          },
          hoverAction: "highlightPoint",
          color: this.greyColors[i * 3],
          states: {
            hover: {
              fill: this.$vuetify.theme.defaults.light.primary,
            },
          },
        });
        i++;
      }

      return points;
    },
    fillChart() {
      this.points = this.jobTypeNumbers();

      setTimeout(() => {
        let chart = this.$refs.jtChart.instance;
        chart.series().options({ points: this.points });
      }, 250);
    },
  },
  // mounted() {
  //   this.fillChart();
  // },
};
</script>
