<template>
  <MenuVue @resetFilter="resetFilter">
    <v-container>
      <v-row
        @click="filter.accepted = !filter.accepted"
        style="margin-bottom: 2px !important"
      >
        <v-col
          cols="auto"
          class="pa-2"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.accepted
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.accepted ? 'primary' : ''"
            >mdi-check</v-icon
          >
        </v-col>
        <v-col class="pa-2">Veröffentlicht</v-col>
      </v-row>

      <v-row
        @click="filter.denied = !filter.denied"
        style="margin-bottom: 2px !important"
      >
        <v-col
          class="pa-2"
          cols="auto"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.denied
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.denied ? 'primary' : ''"
            >mdi-close</v-icon
          >
        </v-col>
        <v-col class="pa-2">Abgelehnt</v-col>
      </v-row>

      <v-row
        @click="filter.open = !filter.open"
        style="margin-bottom: 2px !important"
      >
        <v-col
          cols="auto"
          class="pa-2"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.open
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.open ? 'primary' : ''"
            >mdi-clock-outline</v-icon
          >
        </v-col>
        <v-col class="pa-2">Nicht bestätigt</v-col>
      </v-row>

      <v-row
        @click="filter.expired = !filter.expired"
        style="margin-bottom: 2px !important"
      >
        <v-col
          cols="auto"
          class="pa-2"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.expired
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.expired ? 'primary' : ''"
            >mdi-calendar-remove</v-icon
          >
        </v-col>
        <v-col class="pa-2">Abgelaufen</v-col>
      </v-row>
    </v-container>
  </MenuVue>
</template>

<script>
import MenuVue from "../../Main/FilterMenu.vue";

export default {
  name: "requestFilter",
  data() {
    return {
      filter: {
        open: false,
        accepted: false,
        denied: false,
        expired: false
      },
    };
  },

  watch: {
    filter: {
      handler() {
        this.$emit("changeFilterQ", this.getFilterQ());
      },
      deep: true,
    },
  },

  components: {
    MenuVue,
  },

  methods: {
    resetFilter() {
      this.filter.open = false;
      this.filter.accepted = false;
      this.filter.denied = false;
      this.filter.expired = false;
    },
    getFilterQ() {
      let query = "";
      if (this.filter.open) {
        query += "&open=1";
      }

      if (this.filter.accepted) {
        query += "&accepted=1";
      }

      if (this.filter.denied) {
        query += "&denied=1";
      }

      if (this.filter.expired) {
        query += "&expired=1";
      }

      return query;
    },
  },
};
</script>
