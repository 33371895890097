<template>
  <div id="eventPage">
    <!-- <div style="background-color: var(--v-accent-base); padding: 8px">
      <h1 class="black--text">Eventübersicht</h1>
    </div> -->

    <v-container class="mt-12 pa-4" :fluid="isMobile">
      <v-row v-if="events.length == 0">
        <v-col class="px-3">
          <p class="primaryText" style="text-align: center">Derzeit keine Events!</p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-spacer v-if="!isMobile" />
        <v-col :cols="cols" class="px-3">
          <v-expansion-panels v-model="panels">
            <v-expansion-panel
              v-for="(event, index) in events"
              :key="index"
              class="mb-2"
              :id="'eventPageEntry_' + event.id"
            >
              <v-expansion-panel-header
                style="min-height: 8em"
                class="ma-0 pa-0"
                tabindex="1"
              >
                <template v-slot:default="{}">
                  <v-row class="ma-0 pa-0" style="height: inherit">
                    <v-col
                      class="ma-0 pa-0"
                      :cols="imageCol"
                      style="
                        height: inherit;
                        background-size: contain;
                        background-position-y: center;
                      "
                      :style="{
                        'background-image': event.logo
                          ? 'url(' + event.logo + ')'
                          : 'url(/img/noImg.fb6bc55f.svg)',
                      }"
                    >
                    </v-col>
                    <v-col class="ml-1">
                      <v-card-text class="pa-0 ma-0">
                        {{ event.start_date | moment("DD.MM.YY") }} -
                        {{ event.end_date | moment("DD.MM.YY") }} <br />
                      </v-card-text>
                      <v-card-title class="pa-0 ma-0"
                        ><b>{{ event.title }}</b></v-card-title
                      >
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="expansionPanelRemoveWrapSpacing">
                <v-container>
                  <v-row>
                    <v-col :cols="leftEventCol">
                      <b>Adresse:</b><br />
                      <span v-if="event.Address">
                        {{ event.Address.street }}<br />
                        {{ event.Address.City.postcode + " " + event.Address.City.city }}
                      </span>
                      <span v-else>Digital</span>
                      <br /><br />
                      <span
                        v-if="
                          event.weblink &&
                          event.weblink != 'undefined' &&
                          event.weblink != 'null'
                        "
                        ><b>Webseite:</b><br />
                        <a
                          style="text-overflow: ellipsis; word-break: break-all"
                          @click="gotoExternalPage(event.weblink)"
                          @keyup.enter="gotoExternalPage(event.weblink)"
                          >{{ event.weblink }}</a
                        >
                        <br /><br />
                      </span>

                      <span
                        ><b> Wann? </b><br />
                        {{ event.start_date | moment("DD.MM.YY") }},
                        {{ getReadableTime(event.start_time) }} Uhr
                      </span>
                      <br />
                      <span>
                        <b> Bis: </b><br />
                        {{ event.end_date | moment("DD.MM.YY") }},
                        {{ getReadableTime(event.end_time) }} Uhr
                      </span>
                    </v-col>
                    <v-col cols="12" v-if="leftEventCol > 6">
                      <hr />
                    </v-col>
                    <v-col :cols="rightEventCol">
                      <span class="encodeFont" v-html="event.description"></span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-spacer v-if="!isMobile" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "eventPage",

  props: ["events"],

  data() {
    return {
      panels: undefined,
    };
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        case "lg":
          return "8";
        case "xl":
          return "8";
        default:
          return "6";
      }
    },
    imageCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "3";
        case "sm":
          return "3";
        case "md":
          return "2";
        case "lg":
          return "2";
        case "xl":
          return "2";
        default:
          return "2";
      }
    },
    leftEventCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        default:
          return "2";
      }
    },
    rightEventCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "12";
        default:
          return "10";
      }
    },
  },

  methods: {
    getReadableTime(value) {
      if (!value) return "--";
      let temp = value.split(":");
      return temp[0] + ":" + temp[1];
    },
    gotoExternalPage(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
  },

  mounted() {
    window.addEventListener("gotoEvent", (e) => {
      console.log(e);

      this.panels = undefined;

      setTimeout(() => {
        let prev = undefined;

        for (let i = 0; i < this.events.length; i++) {
          if (this.events[i].id == e.detail) {
            this.panels = i;

            setTimeout(() => {
              let scrollAnchor
              if(prev){
                scrollAnchor = "eventPageEntry_" + prev
              }
              else{
                scrollAnchor = "eventPage"
              }
              let el = document.getElementById(scrollAnchor);

              el.scrollIntoView();
            }, 100);

            return;
          }

          prev = this.events[i].id
        }
      }, 100);
    });
  },
};
</script>
