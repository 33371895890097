<template>
  <MenuVue @resetFilter="resetFilter">
    <v-container>
      <v-row
        @click="filter.internal = !filter.internal"
        style="margin-bottom: 2px !important"
      >
        <v-col
          cols="auto"
          class="pa-2"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.internal
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.internal ? 'primary' : ''"
            >mdi-home-outline</v-icon
          >
        </v-col>
        <v-col class="pa-2">Intern</v-col>
      </v-row>

      <v-row
        @click="filter.external = !filter.external"
        style="margin-bottom: 2px !important"
      >
        <v-col
          class="pa-2"
          cols="auto"
          style="border-radius: 50%"
          :style="{
            'background-color': filter.external
              ? 'var(--v-accent2-lighten5)'
              : 'transparent',
          }"
        >
          <v-icon class="pa-0" size="xx-large" :color="filter.external ? 'primary' : ''"
            >mdi-export</v-icon
          >
        </v-col>
        <v-col class="pa-2">Extern</v-col>
      </v-row>
    </v-container>
  </MenuVue>
</template>

<script>
import MenuVue from "../../Main/FilterMenu.vue";

export default {
  name: "requestFilter",
  data() {
    return {
      filter: {
        internal: false,
        external: false,
      },
    };
  },

  watch: {
    filter: {
      handler() {
        this.$emit("changeFilterQ", this.getFilterQ());
      },
      deep: true,
    },
  },

  components: {
    MenuVue,
  },

  methods: {
    resetFilter() {
      this.filter.internal = false;
      this.filter.external = false;
    },
    getFilterQ() {
      let query = "";
      if (this.filter.internal) {
        query += "&internal=1";
      }

      if (this.filter.external) {
        query += "&external=1";
      }

      return query;
    },
  },
};
</script>
