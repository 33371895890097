<template>
  <div>
    <v-navigation-drawer
       app
      class="pa-4 pb-0 scrollable hiddenScroll"
      
      right
      temporary

      style="overflow-x: hidden; width: 100vw; background-color:#F1F1F1;"
      v-model="drawer"
    >
      <v-btn
        @click="closeDrawer()"
        style="position: absolute; top: 1vh; right: 1vh; z-index:99"
        text
        class="mt-2 headerButton"
      >
        <v-icon style="color: black"> mdi-close </v-icon>
      </v-btn>
      <br />
      <br />

      <searchFilterComponent @search="search" style="margin-bottom: 7vh" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import searchFilterComponent from "./Filter.vue";

export default {
  name: "mobileSearchFilter",

  props: ["open"],

  data() {
    return {
      drawer: false,
    };
  },

  watch: {
    open() {
      if (this.open > 0) {this.drawer = true;}
      else{
        this.drawer = false;
      }
    },
  },

  components: {
    searchFilterComponent,
  },

  methods: {
    search(){
      this.$emit("search");
    },
    hideMobileFilter() {
      this.$emit("showFilter", false);
    },
    closeDrawer() {
      this.drawer = false;

      this.$emit("closeDrawer");
    },
  },

   updated(){
    if(!this.drawer){
      this.closeDrawer();
    }
  }
};
</script>
