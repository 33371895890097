var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"smallFont focusable",attrs:{"id":_vm.result.id + '_card',"flat":""},on:{"click":function($event){return _vm.showDetailView(_vm.index)},"focus":function($event){return _vm.showDetailView(_vm.index)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.switchFocus()}}},[(_vm.result)?_c('v-container',{staticClass:"pa-2",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 mb-1"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[(_vm.result.job_name)?_c('h2',{staticClass:"ma-0 primaryText encodeFont"},[_vm._v(" "+_vm._s(_vm.result.job_name)+" ")]):(_vm.result.Job_name)?_c('h2',{staticClass:"ma-0 primaryText encodeFont"},[_vm._v(" "+_vm._s(_vm.result.Job_name.name)+" ")]):_vm._e()])],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"mb-0",staticStyle:{"font-weight":"bold","text-align":"left"}},[_vm._v(" "+_vm._s(_vm.result.title)+" ")])]),_c('v-col',{staticClass:"pr-1 pt-2",staticStyle:{"position":"absolute","right":"0px","top":"0px"},attrs:{"cols":"auto"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.result.Companies),function(company,index){return _c('v-chip',{key:index,staticClass:"mx-1 mb-2"},[_vm._v(_vm._s(company.name))])}),1)],1),_c('v-row',{staticClass:"pb-4 px-4"},[_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-chip',{style:({
            'background-color':
              _vm.result.job_type == 'Praktikum'
                ? 'var(--v-primary-base) !important'
                : 'var(--v-accent-base) !important',
            color:
              _vm.result.job_type == 'Praktikum' ? 'white !important' : 'black !important',
          })},[_vm._v(" "+_vm._s(_vm.result.job_type)+" ")]),_c('v-chip',[_vm._v(" Beginn: "+_vm._s(_vm._f("moment")(_vm.result.job_start,"DD.MM.YY"))+" ")]),(_vm.result.job_end)?_c('v-chip',[_vm._v(" Bis: "+_vm._s(_vm._f("moment")(_vm.result.job_end,"DD.MM.YY"))+" ")]):_c('v-chip',[_vm._v(" Bis: -- ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }