<template>
  <div>
    <v-expansion-panels v-model="panel" multiple flat>
      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header style="min-height: fit-content" class="py-3"
          ><h2 class="encodeFont px-2">Art</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <hr />
          <div v-for="(tO, index) in typeOptions" :key="index">
            <v-checkbox
              :label="tO"
              class="hideInputMessage"
              v-model="typeCheckboxValues[index]"
            >
            </v-checkbox>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header style="min-height: fit-content" class="py-3"
          ><h2 class="encodeFont px-2">Startdatum</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <hr />
          <v-radio-group v-model="searchFilters.startDateFilter">
            <v-radio
              v-for="(sDO, index) in startDateOptions"
              :key="index"
              :label="sDO"
              :value="index"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header style="min-height: fit-content" class="py-3"
          ><h2 class="encodeFont px-2">Veröffentlichungsdatum</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <hr />
          <v-radio-group v-model="searchFilters.createdFilter">
            <v-radio
              v-for="(pDO, index) in publishedDateOptions"
              :key="index"
              :label="pDO"
              :value="index"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="my-2 rounded-lg">
        <v-expansion-panel-header style="min-height: fit-content" class="py-3"
          ><h2 class="encodeFont px-2">Lebenswelten</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <hr />
          <div v-for="(bW, index) in brafoWorlds" :key="index">
            <b>{{ bW }}</b>
            <div v-for="(aF, index2) in activityFields[index]" :key="index2" class="pl-6">
              <v-checkbox
                :label="aF"
                class="hideInputMessage"
                v-model="searchFilters.brafoSelections[index * 3 + index2]"
              >
              </v-checkbox>
            </div>
            <hr v-if="index != 3" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn block large class="my-6" @click="search">Suchen</v-btn>
    <br />
  </div>
</template>

<script>
export default {
  name: "searchFilter",

  data() {
    return {
      panel: [0, 1, 2, 3],
      typeOptions: ["Ausbildung", "Praktikum"],
      startDateOptions: [],
      publishedDateOptions: ["Alle", "Heute", "Gestern", "Vor 1 Woche", "Vor 2 Wochen"],
      brafoWorlds: [
        "Lebenswelt I - Mensch und Naturtechnik",
        "Lebenswelt II - Mensch und Mitmenschen",
        "Lebenswelt III - Mensch und Kultur",
        "Lebenswelt IV - Mensch und Information/Wissen",
      ],
      activityFields: [
        [
          "Fertigen, verarbeiten und reparieren",
          "Pflanzen anbauen oder Tiere züchten",
          "Recycling, Rohstoffe gewinnen, entsorgen und reinigen",
        ],
        [
          "Gesundheitlich oder sozial helfen und pflegen, Medizinisch oder kosmetisch behandeln",
          "Erziehen, ausbilden und lehren",
          "Sichern und schützen oder den Straßenverkehr regeln",
        ],
        [
          "Werben, Marketing und Öffentlichkeitsarbeit",
          "Bewirten, beherbergen und Speisen bereiten",
          "Künstlerisch, journalistisch und unterhaltend tätig sein",
        ],
        [
          "EDV oder das Erstellen von Zeichnungen, beraten und informieren",
          "Einkaufen oder verkaufen, kassieren, sortieren, packen und beladen",
          "Messen, prüfen, erproben und kontrollieren",
        ],
      ],

      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],

      typeCheckboxValues: [false, false],

      searchFilters: {
        typeFilter: { ausbildung: false, praktikum: false },
        startDateFilter: 0,
        createdFilter: 0,
        brafoSelections: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },
    };
  },

  watch: {
    "$SearchFilterHandler.searchFilters.typeFilter"() {
      this.searchFilters.typeFilter = this.$SearchFilterHandler.getSearchFilters().typeFilter;
      this.typeCheckboxValues = [
        this.searchFilters.typeFilter.ausbildung,
        this.searchFilters.typeFilter.praktikum,
      ];
    },
    typeCheckboxValues() {
      if (this.typeCheckboxValues[0] && !this.searchFilters.typeFilter.ausbildung) {
        this.searchFilters.typeFilter.ausbildung = true;
        this.$SearchFilterHandler.setTypeSelection(this.searchFilters.typeFilter);
      } else if (
        !this.typeCheckboxValues[0] &&
        this.searchFilters.typeFilter.ausbildung
      ) {
        this.searchFilters.typeFilter.ausbildung = false;
        this.$SearchFilterHandler.setTypeSelection(this.searchFilters.typeFilter);
      }

      if (this.typeCheckboxValues[1] && !this.searchFilters.typeFilter.praktikum) {
        this.searchFilters.typeFilter.praktikum = true;
        this.$SearchFilterHandler.setTypeSelection(this.searchFilters.typeFilter);
      } else if (!this.typeCheckboxValues[1] && this.searchFilters.typeFilter.praktikum) {
        this.searchFilters.typeFilter.praktikum = false;
        this.$SearchFilterHandler.setTypeSelection(this.searchFilters.typeFilter);
      }
    },
  },

  methods: {
    
    search() {
      this.$emit("search");
    },
  },

  mounted() {
    let date = new Date();

    this.startDateOptions.push("Ab sofort");

    let firstMonth = date.getMonth();
    for (let i = 0; i < 6; i++) {
      let displayText = this.months[(date.getMonth() + i) % 12];

      if ((date.getMonth() + i) % 12 < firstMonth) {
        displayText += " " + (date.getFullYear() + 1);
      } else {
        displayText += " " + date.getFullYear();
      }

      this.startDateOptions.push(displayText);
    }

    this.startDateOptions.push("Später");

    this.searchFilters = this.$SearchFilterHandler.getSearchFilters();
    this.typeCheckboxValues = [
      this.searchFilters.typeFilter.ausbildung,
      this.searchFilters.typeFilter.praktikum,
    ];

    this.$forceUpdate();
  },
};
</script>
