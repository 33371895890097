<template>
  <div>
    <v-navigation-drawer
      app
      class="pa-0 pb-0 scrollable hiddenScroll"
      right
      temporary
      style="overflow-x: hidden; width: 100vw; background-color:white;"
      v-model="drawer"
      :style="{height: '100vh'}"
    >

      <detailCard @hide="switchVisibility" @edit="showEditView" @delete="showDeleteView" :jobDescriptionData="jobDescriptionData" :isEditView="isEditView" @close="closeDrawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import detailCard from "./ResultDetail_Backoffice.vue"

export default {
  name: "searchByField",

  props: ["open", "jobDescriptionData", "isEditView"],

  data() {
    return {
      drawer: false,
    };
  },

  components: {
    detailCard
  },

  watch: {
    open() {
      if (this.open > 0) this.drawer = true;
    },
  },

  computed: {
    
  },

  methods: {
    switchVisibility(data) {
      this.$emit("hide", data);
    },
    showEditView(data) {
      this.$emit("edit", data);
    },
    showDeleteView(data) {
      this.$emit("delete", data);
    },
    closeDrawer(data) {
      this.drawer = false;

      this.$emit("closeDrawer", data);
    },
  },

  updated(){
    if(!this.drawer){
      this.closeDrawer();
    }
  }
};
</script>
