import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#009A93',
        accent: '#13FFE9',
        accentButLighter: '#edfffd',
        accent2: '#4E6D6B',
        background: '#F1F1F1',
        backgroundButLighter: '#F8F8F8',
        toolbar: '#FFF',
      },
    },
  },

});

export default vuetify