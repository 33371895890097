<template>
  <div>
    <v-navigation-drawer
      app
      class="pa-0 pb-0 scrollable disableOverscroll"
      right
      temporary
      style="overflow-x: hidden; width: 100vw; background-color: white"
      v-model="drawer"
      :style="{ height: '100vh' }"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <eventDetail
        :eventData="eventData"
        @edit="emitEdit"
        @delete="emitDelete"
        @requestCheck="emitRequestCheck"
        @changeStatus="emitChangeStatus"
        class="mt-12"
        :resultView="resultView"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import eventDetail from "../Event/EventDetail.vue";

export default {
  name: "eventDetailMobileDrawer",

  props: ["eventData", "open", "resultView"],

  data() {
    return {
      drawer: false,
    };
  },

  watch: {
    open() {
      if (this.open > 0) {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    },
  },

  components: {
    eventDetail,
  },

  methods: {
    emitEdit(data) {
      this.$emit("edit", data);
    },
    emitDelete(data) {
      this.$emit("delete", data);
    },
    emitRequestCheck(data) {
      this.$emit("requestCheck", data);
    },
    emitChangeStatus(data) {
      this.$emit("changeStatus", data);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
