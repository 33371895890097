<template>
  <div style="height: 100%; width: 100%">
    <v-card
    :ripple="!blacklistView"
      @click="showDetailView()"
      @focus="showDetailView()"
      @keyup.enter="switchFocus()"
      flat
      class="smallFont focusable pa-2"
      style="height: 100%"
    >
      <v-container fluid class="pa-2" style="height: 100%">
        <v-row class="pa-0 ma-0">
          <v-col cols="4" class="pa-0 ma-0">
            <v-img
              v-if="data.logo"
              class="imageContain"
              style="max-height: 80px; min-height: 100%"
              :src="getImgSrc"
            ></v-img>
            <v-img
              v-else
              class="imageCover"
              style="max-height: 80px; min-height: 100%"
              src="../../../assets/images/noImg.svg"
            ></v-img>
          </v-col>
          <v-col cols="7" class="px-4 py-0">
            <p class="mb-0">{{ data.Address.City.city.city ? data.Address.City.city.city : data.Address.City.city}}</p>
            <h2 class="pa-0 ma-0 encodeFont">{{ data.name }}</h2>

            <div v-if="showAddress">
              {{ data.owner }}
              <br />
              <br />
              {{ data.Address.City.postcode }} {{ data.Address.City.city }}<br />
              {{ data.Address.street }} {{ data.Address.additional }}
            </div>
          </v-col>
          <v-col cols="auto" style="position: absolute; right: 0px; top: 0px">
            <v-tooltip bottom v-if="data.external">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">mdi-import </v-icon>
              </template>
              <span>Unternehmen von externer Börse. Kein Registrierter Nutzeraccount</span>
            </v-tooltip>
            <v-tooltip bottom v-if="data.locked && !blacklistView">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary">mdi-cancel </v-icon>
              </template>
              <span>Blockiert (Stellenanzeigen nicht mehr in Suche)</span>
            </v-tooltip>

            <v-tooltip bottom v-if="data.locked && blacklistView">
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="unlockCompany" v-bind="attrs" v-on="on" color="primary">mdi-lock-open-variant-outline </v-icon>
              </template>
              <span>Unternehmen freigeben</span>
            </v-tooltip>

            
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "companyCard",

  props: ["data", "showAddress", "blacklistView"],

  data(){
    return {
      url: axios.defaults.url
    }
  },

  computed: {
    getImgSrc(){
      if(this.data.logo.startsWith("https://")){
        return this.data.logo;
      }
      else{
        return this.url + this.data.logo;
      }
    }
  },

  methods: {
    showDetailView() {
      this.$emit("select", this.data.id);
    },
    unlockCompany(){
      this.$emit("unlock");
    },
    switchFocus(){
      this.$emit("switchFocus");
    },
  },

};
</script>
