<template>
  <div id="profile" style="width: 100%">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="6" class="pa-0">
          <v-card flat>
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-account-multiple</v-icon> Kontaktpersonen</v-card-title
            >
          </v-card>
        </v-col>

        <v-spacer />
        <v-col cols="auto" class="pa-0">
          <v-btn
            @click="showEditContact()"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0 plusButton"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="isMobile ? 12 : 6"
          class="px-0 scrollable hiddenScroll mb-2"
          style="padding-top: 0px; padding-bottom: 0px; margin-top: 12px"
          v-for="(c, index) in contactData"
          :key="c.id + '_c'"
          :class="{
            'pr-0': index % 2 == 0 && !isMobile ? true : false,
            'pl-4': index % 2 != 0 && !isMobile ? true : false,
            'pr-0': index % 2 != 0 && !isMobile ? true : false,
            'pl-0': index % 2 == 0 && !isMobile ? true : false,
            'px-0': isMobile,
          }"
        >
          <v-card :id="'contactPersonCard_' + c.id" elevation="0" class="pa-4">
           
            {{
              c.role == "undefined" || c.role == "" || c.role == "null" ? "Keine Rolle eingetragen" : c.role
            }}
            <h2 class="encodeFont" style="color: var(--v-primary-base)">
              {{
                (!c.first_name || c.first_name == "undefined" || c.first_name == "null"
                  ? ""
                  : c.first_name) +
                " " +
                c.last_name
              }}
            </h2>
            <br />
            <v-icon class="pr-2">mdi-phone</v-icon
            ><a :href="'tel:' + c.phone">{{ c.phone }}</a
            ><br />
            <v-icon class="pr-2">mdi-email</v-icon
            ><a :href="'mailto:' + c.email">{{ c.email }}</a>

            <div class="cardEditButtons">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="showDeleteContact(c)">
                    <v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Löschen</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="showEditContact(c)">
                    <v-icon>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!contactData || contactData.length == 0">
      <v-card class="my-3 pa-0" flat  style="margin: 0 auto">
        <p class="pa-16">
          Keine Kontaktpersonen Vorhanden. 
        </p>
      </v-card>
    </div>

    <v-overlay :value="showContactEditView" :dark="false">
      <EditContactVue
        :contactData="activeContact"
        @close="closeEditView"
      />
    </v-overlay>

    <v-overlay :value="showDeletionWarning" :dark="false">
      <DeletionWarningVue
        v-if="activeContact"
        :data="activeContact.first_name + ' ' + activeContact.last_name"
        item=" die Kontaktperson"
        @cancel="hideDeletionWarning"
        @confirm="deleteContact"
      />
    </v-overlay>
  </div>
</template>

<script>
import EditContactVue from "../../components/Backoffice/Company/ContactEdit.vue";
import DeletionWarningVue from "../../components/Main/DeletionWarning.vue";

const axios = require("axios");

export default {
  name: "contacts",

  data() {
    return {
      contactData: undefined,

      showContactEditView: false,
      activeContact: undefined,

      showDeletionWarning: false,
    };
  },

  components: {
    EditContactVue,
    DeletionWarningVue,
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    closeEditView(data) {
      this.showContactEditView = false;

      if (data) {
        this.getContactData();
      }
    },

    getContactData() {
      axios
        .get(axios.defaults.baseURL + "contactpersons/own")
        .then((resp) => {
          this.contactData = resp.data;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    showEditContact(contact) {
      this.activeContact = contact;
      this.showContactEditView = true;
    },
    showDeleteContact(contact) {
      this.activeContact = contact;
      this.showDeletionWarning = true;
    },
    deleteContact() {
      axios
        .delete(axios.defaults.baseURL + "contactpersons/delete/" + this.activeContact.id)
        .then(() => {
          this.getContactData();
          this.showDeletionWarning = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.showDeletionWarning = false;
        });
    },
    hideDeletionWarning() {
      this.showDeletionWarning = false;
    },
  },

  mounted() {
    this.getContactData();
  },
};
</script>
