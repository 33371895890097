<template>
  <v-container fluid class="pa-0 pt-2">
    <v-row
      class="mb-12"
      :style="{ height: isMobile ? 'unset' : dashboardHeight }"
    >
      <v-col
        :cols="mainCols"
        class="pr-1 pl-0 pt-0"
        :style="{ height: isMobile ? '800px' : 'inherit' }"
      >
        <v-container
          style="height: inherit; display: flex; flex-direction: column;"
        >
          <v-row style="height: 50%">
            <v-col
              cols="12"
              class="pt-0"
              style="height: 100%; cursor: pointer"
              @click="goToCompanies"
            >
              <DashboardStats
                title="Unternehmensfreigabe"
                :amount="acceptedCompanies"
                :greyText="acceptedCompanies > 0 ? 'ausstehend' : 'erledigt'"
                class="statChart"
              />
            </v-col>
          </v-row>
          <v-row style="height: 50%">
            <v-col cols="12" v-if="companies" style="height: 100%">
              <v-card
                flat
                style="height: 100%; display: flex; flex-direction: column;"
              >
              <CompanyChart :companies="companies" class="statChart" label="Registrierte Unternehmen" />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        :cols="mainCols"
        class="pr-0  pl-1 pt-0"
        :style="{ height: isMobile ? '800px' : 'inherit' }"
      >
        <v-container
          style="height: inherit; display: flex; flex-direction: column;"
        >
          <v-row style="height: 50%">
            <v-col
              cols="12"
              class="pt-0"
              style="height: 100%; cursor: pointer"
              @click="goToJobNames"
              :style="{'margin-top': this.$vuetify.breakpoint.smAndDown ? '16px' : ''}"
            >
              <DashboardStats
                title="Berufe zu bearbeiten"
                :amount="openJobNames"
                :greyText="openJobNames > 0 ? 'ausstehend' : 'erledigt'"
                class="statChart"
              />
            </v-col>
          </v-row>
          <v-row style="height: 50%">
            <v-col cols="12" v-if="searches" style="height: 100%">
              <v-card
                flat
                style="height: 100%; display: flex; flex-direction: column;"
                :style="{'margin-top': this.$vuetify.breakpoint.smAndDown ? '16px' : ''}"
              >
                <SearchesCount
                  :searches="searches"
                  label="Suchanfragen insgesamt"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        v-if="externals"
        :cols="isMobile ? 12 : mainCols"
        class="pt-0"
        :class="{ 'pl-5': !isMobile, 'pr-2': !isMobile }"
        style="display: flex; flex-grow: 1; height: 100%"
        :style="{'margin-top': this.$vuetify.breakpoint.mdAndDown ? '16px' : ''}"
      >
        <v-card flat style="width: 100%">
          <ExternalChart
            :externals="externals"
            :dashboard="true"
            label="Schnittstellen Performance"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExternalChart from "@/components/Statistics/ExternalChart.vue";
import SearchesCount from "@/components/Statistics/SearchesCount.vue";
import CompanyChart from "@/components/Statistics/CompanyChart.vue";
import DashboardStats from "@/components/Statistics/DashboardStats.vue";
const axios = require("axios");

export default {
  name: "editorDashboard",
  data() {
    return {
      searches: undefined,
      companies: undefined,
      externals: undefined,
      acceptedCompanies: undefined,
      openJobNames: undefined,
    };
  },
  components: {
    ExternalChart,
    SearchesCount,
    CompanyChart,
    DashboardStats,
  },
  computed: {
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        default:
          return 4;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
    dashboardHeight(){
      if(this.$vuetify.breakpoint.height > 850){
        return 'calc(100vh - 120px)'
      }
      else
      return '1000px'
    }
  },
  methods: {
    fetchData() {
      axios
        .get(axios.defaults.baseURL + "statistics/searches/count/")
        .then((resp) => {
          this.searches = resp.data;
          axios
            .get(axios.defaults.baseURL + "statistics/companies/registered/")
            .then((resp) => {
              this.companies = resp.data;
              axios
                .get(axios.defaults.baseURL + "statistics/externals/")
                .then((resp) => {
                  this.externals = resp.data;

                  axios
                    .get(
                      axios.defaults.baseURL + "statistics/companies/accepted"
                    )
                    .then((resp) => {
                      this.acceptedCompanies = resp.data;

                      axios
                        .get(
                          axios.defaults.baseURL + "statistics/jobnames/todo/"
                        )
                        .then((resp) => {
                          this.openJobNames = resp.data;
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goToCompanies(){
      this.$router.push("unternehmensprofil/authentifizierung")
    },
    goToJobNames(){
      this.$router.push("berufe")
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
