<template>
  <div id="profile" style="width: 100%">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="6" class="pa-0">
          <v-card flat>
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-home</v-icon> Filialenverwaltung</v-card-title
            >
          </v-card>
        </v-col>

        <v-spacer />

        <v-col cols="auto" class="pa-0">
          <v-btn
            @click="showEditCompany($event)"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0 plusButton"
            style="width: 64px"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!validated">
        <v-col
          class="mt-3 text-center"
          style="background-color: rgba(255, 0, 0, 0.2); border-radius: 4px"
        >
          <b>
            Ihr Account wurde noch nicht durch einen Redakteur validiert. Ihre
            Stellenanzeigen werden solange in der Suche nicht angezeigt. Bitte geben Sie
            uns ein paar Tage Zeit, um Ihre Daten zu prüfen.
          </b>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col cols="12" class="pa-0">
          <v-expansion-panels flat>
            <v-expansion-panel
              v-for="company in companyData"
              :key="company.id"
              class="mt-3 coloredExpansionPanel"
            >
              <v-expansion-panel-header class="pa-4">
                <div>
                  <div
                    class="header-line"
                    style="font-size: 1.25rem; color: var(--v-primary-base)"
                  >
                    {{ company.name }}
                  </div>
                  <div class="header-line mt-2">
                    <small
                      >{{ company.Address.street }},

                      <span v-if="company.Address.City.postcode.postcode"
                        >{{ company.Address.City.postcode.postcode }} </span
                      ><span v-else>{{ company.Address.City.postcode }} </span>

                      <span v-if="company.Address.City.city.city">{{
                        company.Address.City.city.city
                      }}</span
                      ><span v-else>{{ company.Address.City.city }}</span>
                    </small>
                  </div>
                </div>

                <template v-slot:actions>
                  <div class="mr-3" v-if="company.locked">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          style="color: var(--v-error-base) !important"
                          >mdi-alert</v-icon
                        >
                      </template>
                      <span>Unternehmen ist gesperrt</span>
                    </v-tooltip>
                  </div>
                  <div @click="showEditCompany($event, company)" class="mr-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="primary"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      Filiale bearbeiten
                    </v-tooltip>
                  </div>
                  <v-icon color="primary"> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <CompanyDetailVue :company="company" />
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="!companyData || companyData.length == 0">
      <v-card class="my-3" :style="{ 'max-width': maxWidth }" style="margin: 0 auto">
        <p class="pa-16">
          Keine Unternehmen Vorhanden. Kontaktieren sie bitte einen Admin, falls ein
          Fehler vorliegt.
        </p>
      </v-card>
    </div>

    <v-overlay :value="showCompanyEditView" :dark="false">
      <EditCompanyVue
        v-if="companyData"
        :companyData="activeCompany"
        @close="closeEditView"
      />
    </v-overlay>
  </div>
</template>

<script>
import EditCompanyVue from "../../components/Backoffice/Company/CompanyEdit.vue";
import CompanyDetailVue from "../../components/Backoffice/Company/CompanyDetail.vue";

const axios = require("axios");

export default {
  name: "companyProfile",

  data() {
    return {
      companyData: undefined,

      activeCompany: undefined,
      showCompanyEditView: false,

      showDeletionWarning: false,

      validated: true,
    };
  },

  components: {
    EditCompanyVue,
    CompanyDetailVue,
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    closeEditView(data) {
      this.showCompanyEditView = false;

      if (data) {
        this.getCompanyData();
      }
    },

    getCompanyData() {
      axios
        .get(axios.defaults.baseURL + "companies/own")
        .then((resp) => {
          this.companyData = resp.data;

          this.fetchValidated();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    fetchValidated() {
      axios.get(axios.defaults.baseURL + "validated").then((resp) => {
        this.validated = resp.data.validated;
      });
    },
    hideDeletionWarning() {
      this.showDeletionWarning = false;
    },
    showEditCompany(event, company) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.activeCompany = company;
      this.showCompanyEditView = true;
    },
  },

  mounted() {
    this.getCompanyData();
  },
};
</script>
