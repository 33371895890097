<template>
  <div>
    <v-navigation-drawer
      class="pa-3 pb-0 hiddenScroll scrollable"
      absolute
      left
      app
      temporary
      style="overflow-x: hidden; width: 100vw; background-color: #f1f1f1"
      v-model="drawer"
    >
      <v-btn
        @click="closeDrawer"
        text
        class="mt-2 headerButton"
        style="position: absolute; top: 1vh; right: 1vh; z-index:99"
      >
        <v-icon style="color: black"> mdi-close </v-icon>
      </v-btn>
      <brafoWorlds @search="search" style="margin-bottom: 7vh" />
      <br />
    </v-navigation-drawer>
  </div>
</template>

<script>
import brafoWorlds from "./BrafoWorlds.vue";

export default {
  name: "searchByField",

  props: ["open"],

  data() {
    return {
      drawer: false,
    };
  },

  components: {
    brafoWorlds,
  },

  watch: {
    open() {
      if (this.open > 0) this.drawer = true;
    },
  },

  computed: {},

  methods: {
    search() {
      this.$emit("search");
    },
    closeDrawer() {
      this.drawer = false;

      this.$emit("closeDrawer");
    },
  },

  updated() {
    if (!this.drawer) {
      this.closeDrawer();
    }
  },
};
</script>
