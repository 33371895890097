<template>
  <v-menu class="filterMenu" v-model="menu" :close-on-content-click="false" bottom style="max-width: 500px; max-height: 700px">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{...tooltip, ...menu}"
            depressed
            large
            block
            class="primaryOnWhiteButton"
          >
            <v-icon size="xx-large">mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        Filter anzeigen
      </v-tooltip>
    </template>

    <v-card min-width="300" class="py-5 px-8" style="position: relative">
      <v-btn icon class="pa-2 py-4 mb-2" @click="menu = false" style="position: absolute; right: 0px; top: 0px">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>

      <v-container fluid>
       <v-row>
        <v-col class="pb-6 pl-0">
          <h3 class="encodeFont">Suchfilter</h3>
        </v-col>
      </v-row>
      </v-container>

      <slot />

      <v-card-actions class="px-0 pt-6">
        <v-spacer></v-spacer>
        <v-btn depressed class="primaryOnWhiteButton lightOutline pa-2 py-4 mb-2" @click="resetFilter()" style="width: 100%">
          Zurücksetzen
        </v-btn>
        <!-- <v-btn depressed class="primaryButton pa-2 py-4 mb-2" @click="menu = false">
          Schließen
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name: "filterMenuComponent",

  data() {
    return {
      menu: false,
    };
  },

  watch: {
    menu(){
      if(this.menu){
        this.$emit("openMenu")
      }
    }
  },

  methods: {
    resetFilter(){
      this.$emit("resetFilter")
    }
  },
};
</script>
