var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"},attrs:{"id":"profile"}},[_c('v-container',{staticStyle:{"margin":"0 auto"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":6}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"encodeFont"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-multiple")]),_vm._v(" Kontaktpersonen")],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primaryOnWhiteButton pa-0 plusButton",attrs:{"large":"","block":"","elevation":"0"},on:{"click":function($event){return _vm.showEditContact()}}},[_c('v-icon',[_vm._v(" mdi-plus")])],1)],1)],1),_c('v-row',_vm._l((_vm.contactData),function(c,index){return _c('v-col',{key:c.id + '_c',staticClass:"px-0 scrollable hiddenScroll mb-2",class:{
          'pr-0': index % 2 == 0 && !_vm.isMobile ? true : false,
          'pl-4': index % 2 != 0 && !_vm.isMobile ? true : false,
          'pr-0': index % 2 != 0 && !_vm.isMobile ? true : false,
          'pl-0': index % 2 == 0 && !_vm.isMobile ? true : false,
          'px-0': _vm.isMobile,
        },staticStyle:{"padding-top":"0px","padding-bottom":"0px","margin-top":"12px"},attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('v-card',{staticClass:"pa-4",attrs:{"id":'contactPersonCard_' + c.id,"elevation":"0"}},[_vm._v(" "+_vm._s(c.role == "undefined" || c.role == "" || c.role == "null" ? "Keine Rolle eingetragen" : c.role)+" "),_c('h2',{staticClass:"encodeFont",staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(" "+_vm._s((!c.first_name || c.first_name == "undefined" || c.first_name == "null" ? "" : c.first_name) + " " + c.last_name)+" ")]),_c('br'),_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-phone")]),_c('a',{attrs:{"href":'tel:' + c.phone}},[_vm._v(_vm._s(c.phone))]),_c('br'),_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-email")]),_c('a',{attrs:{"href":'mailto:' + c.email}},[_vm._v(_vm._s(c.email))]),_c('div',{staticClass:"cardEditButtons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showDeleteContact(c)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showEditContact(c)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])])],1)],1)],1)}),1)],1),(!_vm.contactData || _vm.contactData.length == 0)?_c('div',[_c('v-card',{staticClass:"my-3 pa-0",staticStyle:{"margin":"0 auto"},attrs:{"flat":""}},[_c('p',{staticClass:"pa-16"},[_vm._v(" Keine Kontaktpersonen Vorhanden. ")])])],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.showContactEditView,"dark":false}},[_c('EditContactVue',{attrs:{"contactData":_vm.activeContact},on:{"close":_vm.closeEditView}})],1),_c('v-overlay',{attrs:{"value":_vm.showDeletionWarning,"dark":false}},[(_vm.activeContact)?_c('DeletionWarningVue',{attrs:{"data":_vm.activeContact.first_name + ' ' + _vm.activeContact.last_name,"item":" die Kontaktperson"},on:{"cancel":_vm.hideDeletionWarning,"confirm":_vm.deleteContact}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }