<template>
  <v-container fluid style="margin: 0 auto; width: 100%" class="mb-16">
    <v-row class="mb-6">
      <v-col :cols="isMobile ? 12 : 6" style="display: flex" class="pa-0">
        <v-container fluid class="pa-0">
          <v-row class="ma-0">
            <v-col :cols="12" class="pa-0">
              <v-card flat>
                <v-card-title class="encodeFont">
                  <v-icon class="mr-2">mdi-poll</v-icon> Statistiken
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row :style="{ height: isMobile ? 'unset' : 'calc(100vh - 120px)' }">
      <v-col
        :cols="topRowCols"
        :style="{ height: isMobile ? '800px' : 'inherit' }"
      >
        <v-container class="pa-0" fluid style="height: inherit;">
          <v-row class="pa-0" style="height: 50%">
            <v-col
              cols="12"
              class="py-3 px-0 pr-1"
              style="height: 100%"
            >
              <v-card
                flat
                style="height: 100%; display: flex; flex-direction: column; border-bottom-left-radius: 4px !important; border-bottom-right-radius: 4px !important"
              >
                <SearchtermChart
                  :searchTerms="searchTerms"
                  style="height: 100%"
                  :label="'Top 10 gesuchte Berufsbezeichnungen'"
                />
              </v-card>
            </v-col>
          </v-row>

          <v-row style="height: 50%">
            <v-col
              :cols="secondaryCols"
              class="py-0 px-0 pr-2"
              style="flex-grow: 1"
            >
              <v-card
                flat
                style="height: 100%; display: flex; flex-direction: column;"
              >
                <QueryChart
                  :queries="queries"
                  style="height: 100%"
                  label="Verwendung Suchbegriffe"
                />
              </v-card>
            </v-col>

            <v-col
              :cols="secondaryCols"
              class="py-0 pl-1 pr-1"
              style="flex-grow: 1"
            >
              <v-card
                flat
                style="height: 100%; display: flex; flex-direction: column"
              >
                <SearchesCount
                  :searches="searches"
                  style="height: 100%"
                  label="Suchanfragen insgesamt"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        cols="4"
        class="px-0 pl-2 pb-0"
        style="height: 100%;"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column;"
        >
          <ExternalChart
            :externals="externals"
            label="Schnittstellen Performance"
            style="height: 100%;"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.mdAndDown" class="px-0 pr-1">
      <v-col
        :cols="12"
        style="height: 100%; display:flex; flex-grow: 1"
        class="px-0"
      >
        <v-card flat style="width: 100%">
          <ExternalChart
            :externals="externals"
            label="Schnittstellen Performance"
            style="height: 100%"
            :dashboard="isMobile ? false : true"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row
      :style="{ height: $vuetify.breakpoint.xs ? '800px' : '400px' }"
      :class="$vuetify.breakpoint.mdAndDown ? 'mt-0' : ''"
    >
      <v-col
        :cols="
          $vuetify.breakpoint.xs ? 12 : $vuetify.breakpoint.mdAndDown ? 6 : 4
        "
        class="px-0 pr-2"
        style="width: 100% display: flex; flex-grow: 1"
        :style="{ height: $vuetify.breakpoint.xs ? '50%' : '100%' }"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <JobTypeChart
            label="Job-Art-Suche"
            :jobTypes="jobTypes"
            style="height: 100%"
          />
        </v-card>
      </v-col>

      <v-col
        :cols="
          $vuetify.breakpoint.xs ? 12 : $vuetify.breakpoint.mdAndDown ? 6 : 4
        "
        class="px-0 px-1"
        style="width: 100% display: flex; flex-grow: 1"
        :style="{ height: $vuetify.breakpoint.xs ? '50%' : '100%' }"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <PerimeterChart
            label="Umkreissuche"
            :perimeters="perimeters"
            style="height: 100%"
          />
        </v-card>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        :cols="$vuetify.breakpoint.mdAndDown ? 6 : 4"
        class="px-0 pl-2"
        style="height: 100%; width: 100% display: flex; flex-grow: 1"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <BrafoChart
            label="Brafo-Lebenswelten"
            :brafos="brafos"
            style="height: 100%;"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.sm">
      <v-col
        :cols="secondaryCols"
        class="px-0 pt-0 pr-1"
        style="width: 100%; display: flex; flex-grow: 1"
      >
        <v-card
          flat
          style="height: 100%; width:100%; display: flex; flex-direction: column"
        >
          <BrafoChart
            label="Brafo-Lebenswelten"
            :brafos="brafos"
            style="height: 100%;"
          />
        </v-card>
      </v-col>
      <v-col :cols="secondaryCols" class="pt-0 px-1">
        <v-card
          flat
          style="height: 480px; display: flex; flex-direction: column"
        >
          <CompanyChart
            label="Registrierte Unternehmen"
            :companies="companies"
            style="height: 100%"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col
        :cols="12"
        class="px-0 pt-0 pr-1"
        style="height: 480px; width: 100%; display: flex; flex-grow: 1"
      >
        <v-card
          flat
          style="height: 100%; width:100%; display: flex; flex-direction: column"
        >
          <BrafoChart
            label="Brafo-Lebenswelten"
            :brafos="brafos"
            style="height: 100%;"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col v-if="companies" :cols="secondaryCols" class="pt-0 px-1">
        <v-card
          flat
          style="height: 480px; display: flex; flex-direction: column"
        >
          <CompanyChart
            label="Registrierte Unternehmen"
            :companies="companies"
            style="height: 100%"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row class="py-0">
      <v-col
        :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8"
        class="py-0 px-0 pr-1"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <CitiesChart
            :label="
              isMobile
                ? 'Top 8 Städte der letzten 90 Tage'
                : 'Top 8 Städte - Laut Suchanfragen der letzten 90 Tage'
            "
            :cities="cities"
            class="statChart"
            style="height: 100%"
          />
        </v-card>
      </v-col>

      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        cols="4"
        class="py-0 px-0 pl-2"
      >
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <CompanyChart
            label="Registrierte Unternehmen"
            :companies="companies"
            class="statChart"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row style="height: 480px">
      <v-col cols="12" class="px-0 py-3">
        <v-card
          flat
          style="height: 100%; display: flex; flex-direction: column"
        >
          <ClicksChart
            label="Seiten-Aufrufe"
            :clicks="clicks_per_day"
            style="flex-grow: 1"
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay> -->
  </v-container>
</template>

<script>
import JobTypeChart from "@/components/Statistics/JobTypeChart.vue";
import BrafoChart from "../../components/Statistics/BrafoChart.vue";
import ExternalChart from "@/components/Statistics/ExternalChart.vue";
import QueryChart from "@/components/Statistics/QueryChart.vue";
import PerimeterChart from "@/components/Statistics/PerimeterChart.vue";
import CompanyChart from "@/components/Statistics/CompanyChart.vue";
import ClicksChart from "@/components/Statistics/ClicksChart.vue";
import CitiesChart from "@/components/Statistics/CitiesChart.vue";
import SearchtermChart from "@/components/Statistics/SearchtermChart.vue";
import SearchesCount from "@/components/Statistics/SearchesCount.vue";

const axios = require("axios");

export default {
  name: "stats",

  data() {
    return {
      brafos: undefined,
      jobTypes: undefined,
      searchTerms: undefined,
      externals: undefined,
      clicks: undefined,
      clicks_today: undefined,
      queries: undefined,
      perimeters: undefined,
      clicks_per_day: undefined,
      companies: undefined,
      cities: undefined,
      searches: undefined,

      days: undefined,
      loading: undefined,
    };
  },
  components: {
    BrafoChart,
    JobTypeChart,
    ExternalChart,
    QueryChart,
    // PageClicks,
    PerimeterChart,
    CompanyChart,
    ClicksChart,
    CitiesChart,
    SearchtermChart,
    SearchesCount,
    // SearchTermBars,
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    topRowCols() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "12";
      } else return "8";
    },
    secondaryCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "6";
        default:
          return "6";
      }
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(axios.defaults.baseURL + "statistics/")
        .then((resp) => {
          this.searchTerms = resp.data.search_terms;
          this.queries = resp.data.queries;
          this.searches = resp.data.searches;
          this.externals = resp.data.externals;
          this.clicks = resp.data.clicks_total;
          this.clicks_today = resp.data.clicks_today;
          this.clicks_per_day = resp.data.clicks_per_day;
          this.brafos = resp.data.brafos;
          this.companies = resp.data.companies;
          this.jobTypes = resp.data.job_types;
          this.perimeters = resp.data.perimeters;

          this.loading = false;

          axios
            .get(axios.defaults.baseURL + "statistics/additional/")
            .then((resp) => {
              this.cities = resp.data.cities;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount() {
    this.fetchData();
  },
};
</script>
