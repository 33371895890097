<template>
  <div id="event" style="    width: 100%;">
    <v-container fluid style="margin: 0 auto; width: 100%">
      <v-row>
        <v-col :cols="12" style="display: flex" class="pa-0">
           <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="6" class="pa-0 pr-1">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="12" class="pa-0">
                <v-card flat>
                  <v-card-title class="encodeFont"
                    ><v-icon class="mr-2">mdi-calendar</v-icon>
                    Eventübersicht</v-card-title
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col
          cols="auto"
          style="display: flex"
          class="py-0"
          :class="{ 'mt-2': isMobile }"
        >
          <v-container fluid class="pa-0">
            <v-row class="ma-0" style="height: 100%">
              <v-col cols="auto" style="height: 100%" class="pa-0">
                <MenuVue @changeFilterQ="changeFilterQ" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>



        <v-spacer />

        <v-col cols="auto" class="pa-0 pl-3">
          <v-btn
            @click="showNewEvent = true"
            large
            block
            elevation="0"
            style="width: 64px"
            class="primaryOnWhiteButton pa-0"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="my-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ totalEvents }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          :cols="isMobile ? 12 : 5"
          class="px-0"
          style="
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-bottom: 2px;
            margin-top: 12px;
          "
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <div class="scrollable" id="scrollBox" :class="{ disableOverscroll: isMobile }">
            <div
              v-for="(event, index) in eventData"
              :key="event.id + '_card_' + cardKeyHelper"
              :id="event.id + '_card'"
              class="mb-3 focusable"
              @click="setActiveEvent(event)"
              tabindex="0"
              @focus="setActiveEvent(event)"
              @keyup.enter="switchFocus()"
              style="border-radius: 4px !important"
            >
              <EventCardvue :eventData="event" :editView="true" style="border-radius: 4px !important" />

              <div
                v-if="index == eventData.length - 1"
                tabindex="0"
                @focus="rerouteFocus"
                style="opacity: 0; height: 0px"
              >
                Tastatursteuerung Hilfsanker Start
              </div>
            </div>
          </div>

          <v-pagination
            id="paginator"
            class="mt-2 focusableNoBorder"
            tabindex="0"
            v-model="page"
            :length="maxPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-col
          cols="7"
          v-if="!isMobile"
          class="pb-0 pr-0"
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <EventDetailVue
            :height="getContainerHeight"
            :eventData="activeEvent"
            @edit="showEditView = true"
            @delete="showDeletionWarning = true"
            @requestCheck="sendRequestCheck()"
            @changeStatus="changeStatus"
            @resetFocus="resetFocus"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="showEditView" :dark="false" style="z-index: 12 !important">
      <EventEditVue :editorView="true" :eventData="activeEvent" @close="hideEditView" />
    </v-overlay>

    <v-overlay :value="showNewEvent" :dark="false" style="z-index: 12 !important">
      <EventEditVue
        :editorView="true"
        :eventData="undefined"
        @close="closeNewEventView"
      />
    </v-overlay>

    <v-overlay
      :value="showDeletionWarning"
      v-if="activeEvent"
      :dark="false"
      style="z-index: 12 !important"
    >
      <DeletionWarningVue
        :item="' das Event'"
        :data="activeEvent.title"
        @cancel="showDeletionWarning = false"
        @confirm="deleteEvent"
      />
    </v-overlay>

    <EventDrawerMobileVue
      :eventData="activeEvent"
      @edit="showEditView = true"
      @delete="showDeletionWarning = true"
      @requestCheck="sendRequestCheck()"
      @changeStatus="changeStatus"
      :open="eventDrawer"
      @close="eventDrawer = 0"
    />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import EventDetailVue from "../../components/Backoffice/Event/EventDetail.vue";
import EventCardvue from "../../components/Backoffice/Event/EventCard.vue";

import EventEditVue from "../../components/Backoffice/Event/EventEdit.vue";

import DeletionWarningVue from "../../components/Main/DeletionWarning.vue";

import EventDrawerMobileVue from "../../components/Backoffice/Event/EventDetail_MobileDrawer.vue";

import MenuVue from "@/components/Backoffice/Event/EventFilter.vue";

const axios = require("axios");

export default {
  name: "editorEventList",

  data() {
    return {
      eventData: [],
      activeEvent: undefined,

      showDeletionWarning: false,
      showEditView: false,
      showNewEvent: false,

      eventDrawer: 0,

      loading: false,

      page: 1,
      maxPages: undefined,

      cardKeyHelper: 0,

      filterQ: undefined,

      totalEvents: 0,
    };
  },

  watch: {
    page() {
      this.requestEvents();
    },
    filterQ() {
      if (this.page == 1) {
        this.requestEvents();
      } else {
        this.page = 1;
      }
    },
  },

  components: {
    EventCardvue,
    EventDetailVue,
    EventEditVue,
    DeletionWarningVue,
    EventDrawerMobileVue,
    MenuVue,
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },
    getContainerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "unset";
        case "sm":
          return "unset";
        case "md":
          return "70vh";
        case "lg":
          return "80vh";
        case "xl":
          return "80vh";
        default:
          return "80vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    changeFilterQ(data) {
      this.filterQ = data;
    },
    rerouteFocus(e) {
      document.getElementById("paginator").focus();
      e.stopPropagation();
      e.preventDefault();
    },
    resetFocus() {
      for (let i = 0; i < this.eventData.length; i++) {
        if (this.eventData[i].id == this.activeEvent.id) {
          if (i < this.eventData.length - 1) {
            document.getElementById(this.eventData[i + 1].id + "_card").focus();
          } else {
            document.getElementById(this.eventData[0].id + "_card").focus();
          }

          return;
        }
      }
    },
    switchFocus() {
      document.getElementById("eventDetail").focus();
    },
    hideEditView(data) {
      this.showEditView = false;
      if (data) {
        // this.activeEvent = data;
        this.requestEvents(data.id);
      }
    },
    closeNewEventView(data) {
      this.showNewEvent = false;
      if (data) {
        this.requestEvents();
      }
    },
    setActiveEvent(event) {
      this.activeEvent = event;

      if (this.isMobile) {
        this.eventDrawer++;
      }
    },
    deleteEvent() {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "events/patch/" + this.activeEvent.id, {
          id: this.activeEvent.id,
          deleted: true,
        })
        .then((resp) => {
          for (let i = 0; i < this.eventData.length; i++) {
            if (this.eventData[i].id == resp.data.id) {
              this.eventData.splice(i, 1);
              break;
            }
          }

          if (this.eventData.length > 0) this.activeEvent = this.eventData[0];
          else this.activeEvent = null;

          this.showDeletionWarning = false;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
    changeStatus(data) {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "events/editor/patch/" + this.activeEvent.id, {
          id: this.activeEvent.id,
          status: data,
        })
        .then((resp) => {
          for (let i = 0; i < this.eventData.length; i++) {
            if (this.eventData[i].id == this.activeEvent.id) {
              if (resp.data.status == 2) this.eventData[i].status = "Akzeptiert";
              else if (resp.data.status == 3) this.eventData[i].status = "Abgelehnt";
            }
          }

          this.$forceUpdate();
          this.cardKeyHelper++;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          this.loading = false;
        });
    },
    requestEvents(newActiveId) {
      let searchString = "?page=" + this.page;

      if (this.filterQ) {
        searchString += this.filterQ;
      }

      axios
        .get(axios.defaults.baseURL + "events/all/" + searchString)
        .then((resp) => {
          this.eventData = resp.data.results;
          this.totalEvents = resp.data.count

          if (newActiveId) {
            this.activeEvent = resp.data.results[0];

            for (let i = 0; i < resp.data.results.length; i++) {
              if (resp.data.results[i].id == newActiveId) {
                this.activeEvent = resp.data.results[i];
                break;
              }
            }
          } else {
            this.activeEvent = resp.data.results[0];
          }

          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;

          document.getElementById("scrollBox").scrollTop = 0;
        })
        .catch((e) => {
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          console.log(e);
        });
    },
  },

  mounted() {
    setTimeout(() => {
        
    this.requestEvents();
    }, 500);
  },
};
</script>
