<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <v-card flat>
      <div class="py-1 chartHeader" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
        <h2 class="encodeFont pl-2" style="color: white !important">
          {{ title }}
        </h2>
      </div>
    </v-card>
    <v-container
      fluid
      style="background-color: white; display: flex; align-items: center; flex-grow: 1; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;"
    >
      <v-row style="align-items: center">
        <v-col cols="12" style="display: flex; justify-content: center; flex-grow: 0; flex-shrink: 0">
          <h1
            class="encodeFont"
            :style="{color: 'var(--v-primary-base) !important'}"
            style="font-size: xxx-large"
          >
            {{ amount }}
          </h1>
        </v-col>

        <v-col cols="12" class="pb-12">
          <div style="width: 67%; margin: 0 auto" :style="{'background-color': amount == 0 ? '#b9b7b7' : this.$vuetify.theme.themes.light.primary}">
            <h1 class="encodeFont text-center" style="color: white !important">
              {{ greyText }}
            </h1>
          </div>
        </v-col>

        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["title", "amount", "greyText"],

  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "4em";
        case "sm":
          return "4em";
        case "md":
          return "4em";
        case "lg":
          return "6em";
        case "xl":
          return "8em";
        default:
          return "6em";
      }
    },
  },
};
</script>
