<template>
  <div>
    <v-card
      :style="{ width: cardWidth }"
      style="max-height: 90vh"
      class="scrollable px-8 py-12"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form ref="imageForm">
        <v-container>
          <v-row class="mb-2">
            <v-col class="py-0">
              <h2 class="encodeFont">Hintergrundbild</h2>
            </v-col>
          </v-row>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col :cols="isMobile ? 12 : 6" class="pa-0">
                <v-container>
                  <v-row>
                    <v-col class="pb-0">
                      <v-file-input
                        v-model="file"
                        label="Datei"
                        prepend-icon="mdi-paperclip"
                        class="v-input--hide-details hideInputMessage"
                        outlined
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentFile" class="mb-2">
                    <v-col class="py-0">
                      <span>
                        <v-chip class="mr-2"
                          >{{ currentFile.name }}
                          <span @click="removeFile(file)"
                            ><v-icon class="ml-2">mdi-close</v-icon></span
                          ></v-chip
                        >
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>

          <v-row class="mt-2 py-2">
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 4">
              <v-btn
                block
                large
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                :class="{ 'pa-1': isMobile }"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col :cols="isMobile ? 12 : 8">
              <v-btn
                block
                large
                elevation="0"
                class="primaryButton py-3"
                :class="{ 'pa-1': isMobile }"
                @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "imageEdit",
  props: ["imageData"],

  data() {
    return {
      file: [],
      currentFile: undefined,

      required: (value) => !!value || "Pflichtfeld!",

      loading: false,
    };
  },

  components: {},

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "98vw";
        case "sm":
          return "95vw";
        case "md":
          return "90vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    removeFiles(file) {
      this.currentFiles.splice(this.currentFiles.indexOf(file), 1);
    },
    setValues() {
      if (this.imageData) {
        this.currentFile = this.imageData.file;
      } else {
        this.files = undefined;
        this.currentFiles = undefined;
      }
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    close(resp) {
      this.$emit("close", resp);
    },
    saveData() {
      if (this.$refs.imageForm.validate()) {
        this.loading = true;
        
        let formData = new FormData();

        formData.append("file", this.file, this.file.name);
        formData.append("visible", true)
        formData.append("deleted", false)

        if (this.imageData) {
          formData.id = this.imageData.id;

          axios
            .patch(
              axios.defaults.baseURL + "backgroundimages/patch/" + this.imageData.id,
              formData
            )
            .then((resp) => {
              this.$emit("close", resp.data);
              this.loading = false;
            })
            .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              this.loading = false;
              console.log(e);
            });
        } else {
          axios
            .post(axios.defaults.baseURL + "backgroundimages/post/", formData)
            .then((resp) => {
              this.$emit("close", resp.data);
              this.loading = false;
            })
            .catch((e) => {
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              console.log(e);
              this.loading = false;
            });
        }
      }
    },
  },

  mounted() {
    document.getElementById("closeButton").focus();
  },
};
</script>
