var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"eventPage"}},[_c('v-container',{staticClass:"mt-12 pa-4",attrs:{"fluid":_vm.isMobile}},[(_vm.events.length == 0)?_c('v-row',[_c('v-col',{staticClass:"px-3"},[_c('p',{staticClass:"primaryText",staticStyle:{"text-align":"center"}},[_vm._v("Derzeit keine Events!")])])],1):_c('v-row',[(!_vm.isMobile)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"px-3",attrs:{"cols":_vm.cols}},[_c('v-expansion-panels',{model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.events),function(event,index){return _c('v-expansion-panel',{key:index,staticClass:"mb-2",attrs:{"id":'eventPageEntry_' + event.id}},[_c('v-expansion-panel-header',{staticClass:"ma-0 pa-0",staticStyle:{"min-height":"8em"},attrs:{"tabindex":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"height":"inherit"}},[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"inherit","background-size":"contain","background-position-y":"center"},style:({
                      'background-image': event.logo
                        ? 'url(' + event.logo + ')'
                        : 'url(/img/noImg.fb6bc55f.svg)',
                    }),attrs:{"cols":_vm.imageCol}}),_c('v-col',{staticClass:"ml-1"},[_c('v-card-text',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(event.start_date,"DD.MM.YY"))+" - "+_vm._s(_vm._f("moment")(event.end_date,"DD.MM.YY"))+" "),_c('br')]),_c('v-card-title',{staticClass:"pa-0 ma-0"},[_c('b',[_vm._v(_vm._s(event.title))])])],1)],1)]}}],null,true)}),_c('v-expansion-panel-content',{staticClass:"expansionPanelRemoveWrapSpacing"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.leftEventCol}},[_c('b',[_vm._v("Adresse:")]),_c('br'),(event.Address)?_c('span',[_vm._v(" "+_vm._s(event.Address.street)),_c('br'),_vm._v(" "+_vm._s(event.Address.City.postcode + " " + event.Address.City.city)+" ")]):_c('span',[_vm._v("Digital")]),_c('br'),_c('br'),(
                        event.weblink &&
                        event.weblink != 'undefined' &&
                        event.weblink != 'null'
                      )?_c('span',[_c('b',[_vm._v("Webseite:")]),_c('br'),_c('a',{staticStyle:{"text-overflow":"ellipsis","word-break":"break-all"},on:{"click":function($event){return _vm.gotoExternalPage(event.weblink)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoExternalPage(event.weblink)}}},[_vm._v(_vm._s(event.weblink))]),_c('br'),_c('br')]):_vm._e(),_c('span',[_c('b',[_vm._v(" Wann? ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(event.start_date,"DD.MM.YY"))+", "+_vm._s(_vm.getReadableTime(event.start_time))+" Uhr ")]),_c('br'),_c('span',[_c('b',[_vm._v(" Bis: ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(event.end_date,"DD.MM.YY"))+", "+_vm._s(_vm.getReadableTime(event.end_time))+" Uhr ")])]),(_vm.leftEventCol > 6)?_c('v-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e(),_c('v-col',{attrs:{"cols":_vm.rightEventCol}},[_c('span',{staticClass:"encodeFont",domProps:{"innerHTML":_vm._s(event.description)}})])],1)],1)],1)],1)}),1)],1),(!_vm.isMobile)?_c('v-spacer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }